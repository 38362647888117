import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';


import { useDispatch, useSelector } from 'react-redux'

import { Table, Button, Row, Col, Card, Form, Badge, Container, InputGroup, ListGroup } from 'react-bootstrap'


const DataCard = ({ dataset_in }) => {


    const dispatch = useDispatch()

    const [datsetTitle, setDatasetTitle] = useState('')
    const [description, setDescription] = useState('')

    const [nnType, setNNType] = useState('')



    useEffect(() => {

    }, []);



    return (
    
        <div style={{margin:'1px'}}>

            <Card>
                <Card.Header>
                    <p className='h5'>{dataset_in.title} </p>
                </Card.Header>

                <Card.Body>
                    <p>{dataset_in.url}</p>
                    <p>{dataset_in.description} </p>


                </Card.Body>

            </Card>

            
           





            
        </div>
    
    )
}


export default DataCard
