import React , {useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, Card, Form, Button, ListGroup, OverlayTrigger, Popover, Tooltip, Carousel, InputGroup, FormControl, Table } from 'react-bootstrap'



import {loadPagesAdmin, addPage} from '../actions/creatorActions'

export const AdminLibraryScreen = ({match, history}) => {

    
    const dispatch = useDispatch()

    const adminpagelist = useSelector(state => state.adminpagelist)
    const {lpages} = adminpagelist


    const [sectionType, setSectionType] = useState("nodes") 
    const [libraryPageTitle, setLibraryPageTitle] = useState("") 


    const navigateToPage = (pageURL) => {
        history.push(`/admin3123/page/${pageURL}`)
    }

    const selectPageRequest = (module_in) => {
        setSectionType(module_in)
        dispatch(loadPagesAdmin(module_in))
    }

    const createPageRequest = () => {
        dispatch(addPage(sectionType, libraryPageTitle))
    }

    useEffect(() => {
       
        dispatch(loadPagesAdmin(sectionType))

    }, [])





    return(



        <div style={{marginTop:'0px'}}>

            {/* <p className='h4' style={{textAlign:'center'}}>Library</p> */}

            <ListGroup horizontal>
                <ListGroup.Item style={{borderTop:'None', borderBottom:'None', borderLeft:'None'}}>
                    <p className='h4' style={{textAlign:'center', color:'#6b0752'}}>Library</p>
                </ListGroup.Item>
                <ListGroup.Item onClick={()=>selectPageRequest("general")} style={{backgroundColor:sectionType=='general'?'black':'white', color:sectionType=='general'?'white':'black'}}>
                    <p className='h5'>General</p>
                </ListGroup.Item>
                <ListGroup.Item onClick={()=>selectPageRequest("nodes")} style={{backgroundColor:sectionType=='nodes'?'black':'white', color:sectionType=='nodes'?'white':'black'}}>
                    <p className='h5'>Nodes</p>
                </ListGroup.Item>
                <ListGroup.Item onClick={()=>selectPageRequest("lossfunctions")} style={{backgroundColor:sectionType=='lossfunctions'?'black':'white', color:sectionType=='lossfunctions'?'white':'black'}}>
                <p className='h5'>Loss Functions</p>
                </ListGroup.Item>
                <ListGroup.Item onClick={()=>selectPageRequest("optimizers")} style={{backgroundColor:sectionType=='optimizers'?'black':'white', color:sectionType=='optimizers'?'white':'black'}}>
                <p className='h5'>Optimization</p>
                </ListGroup.Item>
                <ListGroup.Item onClick={()=>selectPageRequest("coding")} style={{backgroundColor:sectionType=='coding'?'black':'white', color:sectionType=='coding'?'white':'black'}}>
                <p className='h5'>Coding</p>
                </ListGroup.Item>
                <ListGroup.Item onClick={()=>selectPageRequest("math")} style={{backgroundColor:sectionType=='math'?'black':'white', color:sectionType=='math'?'white':'black'}}>
                <p className='h5'>Math for AI</p>
                </ListGroup.Item>
                <ListGroup.Item onClick={()=>selectPageRequest("models")} style={{backgroundColor:sectionType=='models'?'black':'white', color:sectionType=='models'?'white':'black'}}>
                <p className='h5'>Pre-Built Models</p>
                </ListGroup.Item>
                <ListGroup.Item onClick={()=>selectPageRequest("datasets")} style={{backgroundColor:sectionType=='datasets'?'black':'white', color:sectionType=='datasets'?'white':'black'}}>
                <p className='h5'>Datasets</p>
                </ListGroup.Item>
            </ListGroup>


            <Card>
                <Card.Header>

                    <InputGroup>
                    <Form.Control type='text' placeholder='Title' value={libraryPageTitle} onChange={(e) => setLibraryPageTitle(e.target.value)} style={{backgroundColor:'white'}}> 
                    </Form.Control>

                    <Button onClick={()=>createPageRequest()}>Create Page</Button>
                
                    </InputGroup>
                    
                </Card.Header>
            </Card>




            <Row>
                {lpages && lpages.map((cpage, index)=>(
                    <Col style={{minWidth:'30vw', maxWidth:'31vw', minHeight:'30vh', maxHeight:'31vh', overflow:"scroll"}} onClick={()=>navigateToPage(cpage._id)}>
                    <Card style={{border:'None'}}>
                        
                        <Card.Header>{cpage.title}</Card.Header>
                        <Card.Img>

                        </Card.Img>
                        <Card.Body>
                            {cpage.description}
                        </Card.Body>

                    </Card>
                    </Col>
                ))}
            </Row>
            


            

    



        </div>



    )


}

export default AdminLibraryScreen


