export const LOAD_LECTURE_LIST   = 'LOAD_LECTURE_LIST'
export const LECTURE_LIST_FAIL   = 'LECTURE_LIST_FAIL'

export const LOAD_LECTURE_DETAIL   = 'LOAD_LECTURE_DETAIL'
export const LECTURE_DETAIL_FAIL   = 'LECTURE_DETAIL_FAIL'

export const LOAD_LECTURE_CARDS   = 'LOAD_LECTURE_CARDS'
export const LECTURE_CARDS_FAIL   = 'LECTURE_CARDS_FAIL'

export const LOAD_QUIZ        = 'LOAD_QUIZ'
export const LOAD_QUIZ_FAIL   = 'LOAD_QUIZ_FAIL'