
import {
    UPDATE_LANGUAGE,
    LANGUAGE_FAIL
   
} from "../constants/languageConstants"


 export const languageReducer = (state = { language: 'english'}, action) => {

    switch(action.type){

        case UPDATE_LANGUAGE:
            return { loading: false, language: action.payload }

        case LANGUAGE_FAIL:
            return { loading: false, error: action.payload, language: state.language }

        default:
            return state
    }


}
