import { 
    LOAD_DICTIONARY,
    DICTIONARY_FAIL


 } from "../constants/dictionaryConstants"



 export const dictionaryListReducer = (state = { dictCards: []}, action) => {

    switch(action.type){

        case LOAD_DICTIONARY:
            return { loading: false, dictCards: action.payload }

        case DICTIONARY_FAIL:
            return { loading: false, dictCards: [...state.dictCards, action.payload] }

        default:
            return state
    }

}

