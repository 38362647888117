import { 
    CREATE_MODEL,
    MODEL_FAIL,
    DELETE_MODEL,
    EDIT_MODEL,
    LOAD_MODELS,

    MODEL_DETAIL,
    MODEL_DETAIL_FAIL,

    NN_DETAIL,
    NN_ADD_LAYER,
    NN_UPDATE_LAYER,
    NN_FAIL,

    LOAD_NODES,
    LOAD_NODES_FAIL


 } from "../constants/modelConstants"





 export const nodeListReducer = (state = { nodes: []}, action) => {

    switch(action.type){

        case LOAD_NODES:
            return { loading: false, nodes: action.payload }

        // case CREATE_NODE:
        //     return { loading: false, models: [...state.models, action.payload] }

        case LOAD_NODES_FAIL:
            return { loading: false, error: action.payload, nodes: state.nodes }

        default:
            return state
    }


}





export const lossfunctionReducer = (state = { nodes: []}, action) => {

    switch(action.type){

        case LOAD_NODES:
            return { loading: false, nodes: action.payload }

        // case CREATE_NODE:
        //     return { loading: false, models: [...state.models, action.payload] }

        case LOAD_NODES_FAIL:
            return { loading: false, error: action.payload, nodes: state.nodes }

        default:
            return state
    }


}




export const optimizerReducer = (state = { nodes: []}, action) => {

    switch(action.type){

        case LOAD_NODES:
            return { loading: false, nodes: action.payload }

        // case CREATE_NODE:
        //     return { loading: false, models: [...state.models, action.payload] }

        case LOAD_NODES_FAIL:
            return { loading: false, error: action.payload, nodes: state.nodes }

        default:
            return state
    }


}




export const modeltrainingReducer = (state = { nodes: []}, action) => {

    switch(action.type){

        case LOAD_NODES:
            return { loading: false, nodes: action.payload }

        // case CREATE_NODE:
        //     return { loading: false, models: [...state.models, action.payload] }

        case LOAD_NODES_FAIL:
            return { loading: false, error: action.payload, nodes: state.nodes }

        default:
            return state
    }


}




export const modelevaluateReducer = (state = { nodes: []}, action) => {

    switch(action.type){

        case LOAD_NODES:
            return { loading: false, nodes: action.payload }

        // case CREATE_NODE:
        //     return { loading: false, models: [...state.models, action.payload] }

        case LOAD_NODES_FAIL:
            return { loading: false, error: action.payload, nodes: state.nodes }

        default:
            return state
    }


}












 export const modelListReducer = (state = { models: []}, action) => {

    switch(action.type){

        case LOAD_MODELS:
            return { loading: false, models: action.payload }

        case CREATE_MODEL:
            return { loading: false, models: [...state.models, action.payload] }

        case MODEL_FAIL:
            return { loading: false, error: action.payload, models: state.models }

        default:
            return state
    }


}




export const modelDetailReducer = (state = { modelrc: {}}, action) => {

    switch(action.type){

        case MODEL_DETAIL:
            return { loading: false, modelrc: action.payload }


        case EDIT_MODEL:
            return { loading: false, modelrc: action.payload }


        case MODEL_DETAIL_FAIL:
            return { loading: false, modelrc: action.payload }


        default:
            return state
    }


}




export const NNDetailReducer = (state = { nnrc: {}}, action) => {

    switch(action.type){

        case NN_DETAIL:
            return { loading: false, nnrc: action.payload }


        case NN_ADD_LAYER:
            return { loading: false, nnrc: action.payload }


        case NN_FAIL:
            return { loading: false, error:action.payload, nnrc: state.nnrc }


        default:
            return state
    }


}


export const NNComponentLibraryReducer = (state = { nnrc: {}}, action) => {

    switch(action.type){

        case NN_DETAIL:
            return { loading: false, nnrc: action.payload }


        case NN_ADD_LAYER:
            return { loading: false, nnrc: action.payload }


        case NN_FAIL:
            return { loading: false, error:action.payload, nnrc: state.nnrc }


        default:
            return state
    }


}


export const NNNodesReducer = (state = { nnrc: {}}, action) => {

    switch(action.type){

        case NN_DETAIL:
            return { loading: false, nnrc: action.payload }


        case NN_ADD_LAYER:
            return { loading: false, nnrc: action.payload }


        case NN_FAIL:
            return { loading: false, error:action.payload, nnrc: state.nnrc }


        default:
            return state
    }


}


