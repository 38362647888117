import React , {useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, Card, Form, Button, ListGroup, OverlayTrigger, Popover, Tooltip, Carousel, InputGroup, FormControl, Table } from 'react-bootstrap'


export const AdminHomeScreen = ({match, history}) => {

    
    const dispatch = useDispatch()

    const modelList = useSelector(state => state.modelList)
    const {models} = modelList


    const [newModel, setNewModel] = useState("") 


  

    useEffect(() => {
       

    }, [])


    const navigateTo = (section_in) => {
        history.push(`/admin3123/`+section_in)
    }



    return(



        <div style={{marginTop:'0px'}}>

    
            <ListGroup>
                <ListGroup.Item onClick={()=>navigateTo('library')}>
                    <p className='h4'>Library</p>
                </ListGroup.Item>
                <ListGroup.Item onClick={()=>navigateTo('lectures')}>
                    <p className='h4'>Lectures</p>
                </ListGroup.Item>
                <ListGroup.Item onClick={()=>navigateTo('dictionary')}>
                    <p className='h4'>Dictionary</p>
                </ListGroup.Item>
                <ListGroup.Item onClick={()=>navigateTo('storyline')}>
                    <p className='h4'>Stories</p>
                </ListGroup.Item>
                <ListGroup.Item onClick={()=>navigateTo('datasets')}>
                    <p className='h4'>Datasets</p>
                </ListGroup.Item>
                <ListGroup.Item onClick={()=>navigateTo('references')}>
                    <p className='h4'>References</p>
                </ListGroup.Item>
                <ListGroup.Item onClick={()=>navigateTo('growth')}>
                    <p className='h4'>Metrics</p>
                </ListGroup.Item>
            </ListGroup>


            




        </div>



    )


}

export default AdminHomeScreen


