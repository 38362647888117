export const LOAD_PAGELIST_ADMIN   = 'LOAD_PAGELIST_ADMIN'
export const CREATE_PAGE   = 'CREATE_PAGE'
export const PAGELIST_ADMIN_FAIL   = 'PAGELIST_ADMIN_FAIL'

export const LOAD_PAGE_DETAIL = 'LOAD_PAGE_DETAIL'
export const PAGE_DETAIL_FAIL = 'PAGE_DETAIL_FAIL'

export const LOAD_CARDS     = 'LOAD_CARDS'
export const ADD_CARD     = 'ADD_CARD'
export const CARDS_FAIL   = 'CARDS_FAIL'


export const LOAD_LECTURELIST_ADMIN   = 'LOAD_LECTURELIST_ADMIN'
export const CREATE_LECTURE   = 'CREATE_LECTURE'
export const LECTURELIST_ADMIN_FAIL   = 'LECTURELIST_ADMIN_FAIL'

export const LOAD_LECTURE_DETAIL = 'LOAD_LECTURE_DETAIL'
export const LECTURE_DETAIL_FAIL = 'LECTURE_DETAIL_FAIL'

export const UPDATE_LECTURE_VIDEO = 'UPDATE_LECTURE_VIDEO'
export const UPDATE_LECTURE_VIDEO_FAIL = 'UPDATE_LECTURE_VIDEO_FAIL'


export const LOAD_STORYLIST_ADMIN   = 'LOAD_STORYLIST_ADMIN'
export const ADD_STORY   = 'ADD_STORY'
export const LOAD_STORYLIST_ADMIN_FAIL   = 'LOAD_STORYLIST_ADMIN_FAIL'


export const LOAD_REFERENCELIST_ADMIN   = 'LOAD_REFERENCELIST_ADMIN'
export const ADD_REFERENCE   = 'ADD_REFERENCE'
export const LOAD_REFERENCELIST_ADMIN_FAIL   = 'LOAD_REFERENCELIST_ADMIN_FAIL'


export const LOAD_DICTIONARY_ADMIN   = 'LOAD_DICTIONARY_ADMIN'
export const ADD_DICTIONARY   = 'ADD_DICTIONARY'
export const LOAD_DICTIONARY_ADMIN_FAIL   = 'LOAD_DICTIONARY_ADMIN_FAIL'


export const LOAD_QUIZ_ADMIN   = 'LOAD_QUIZ_ADMIN'
export const ADD_QUIZ   = 'ADD_QUIZ'
export const LOAD_QUIZ_ADMIN_FAIL   = 'LOAD_QUIZ_ADMIN_FAIL'

export const LOAD_QUESTIONS         = 'LOAD_QUESTIONS'
export const ADD_QUIZ_QUESTION      = 'ADD_QUIZ_QUESTION'
export const UPDATE_QUIZ_QUESTION   = 'UPDATE_QUIZ_QUESTION'
export const DELETE_QUIZ_QUESTION   = 'DELETE_QUIZ_QUESTION'
export const QUIZ_QUESTIONS_FAIL    = 'QUIZ_QUESTIONS_FAIL'


export const LOAD_DATASET_ADMIN   = 'LOAD_DATASET_ADMIN'
export const ADD_DATASET   = 'ADD_DATASET'
export const LOAD_DATASET_ADMIN_FAIL   = 'LOAD_DATASET_ADMIN_FAIL'
