import React , {useState, useEffect,  Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { Row, Col, Card, Button, Form,  OverlayTrigger, Tooltip, InputGroup, ListGroup, Badge } from 'react-bootstrap'

import Moment from 'react-moment';

import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';

import {updateCardItem} from '../../../actions/creatorCardActions'

export const CodeTextItem = ({item_in, item_id, card_id, mode_in}) => {

    const dispatch = useDispatch()

    const [sessionMode, setSessionMode] = useState("")
    const [text, setText] = useState('')

    const [viewMode, setViewMode] = useState('view')
    const toggleViewMode = () => {

        console.log("View toggle", viewMode)

        if(viewMode == 'view'){
            setViewMode('edit')
            setText(item_in.text)
        }else{
            setViewMode('view')
        }
    }

    const deleteItem = () => {

    }


    useEffect(() => {
        
        setText(item_in.text)
    }, [])

    const updateTextItem = () => {

        setViewMode('view')
        var newItem = item_in
        newItem.text = text
        dispatch(updateCardItem(card_id, item_id, newItem))

    }
   
    

    return (

        <>

           
            {viewMode == 'view' ? (

            <>

                <SyntaxHighlighter language="python">
                    {item_in.text}
                </SyntaxHighlighter>
                

            </>


            ): (

            <>
            {viewMode == 'edit' && (
                <InputGroup>
                    <Form.Control type='text' placeholder='text' value={text} onChange={(e) => setText(e.target.value)} style={{backgroundColor:'white'}}> 
                    </Form.Control>
                    <Badge>
                        <i className="fas fa-check" onClick={()=>updateTextItem()}></i>
                    </Badge>
                </InputGroup>
                )}

            </>

            )}


            {mode_in == 'edit' && (
                <span>
                    <Badge style={{padding:'5px'}} onClick={()=>toggleViewMode()}><i className="fas fa-edit" ></i></Badge>
                    <Badge style={{padding:'5px'}} onClick={()=>deleteItem()}><i className="fas fa-trash" ></i></Badge>
                </span>
            )}


                
            
                

        </>

    )
}


export default CodeTextItem
