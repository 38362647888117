import React , {useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, Card, Form, Button, ListGroup, OverlayTrigger, Popover, Tooltip, Carousel, InputGroup, FormControl, Table } from 'react-bootstrap'

import {addStory, loadStoryline} from '../actions/creatorActions'

export const AdminStorylineScreen = ({match, history}) => {

    
    const dispatch = useDispatch()

    const adminaistoryline = useSelector(state => state.adminaistoryline)
    const {stories} = adminaistoryline


    const [newModel, setNewModel] = useState("") 

    const [storyDate, setStoryDate] = useState("")
    const [dateRef, setDateRef] = useState("")
    const [field, setField] = useState("")
    const [keyPeople, setKeyPeople] = useState("")
    const [storyTitle, setStoryTitle] = useState("")
    const [storySummary, setStorySummary] = useState("")
    const [storyHashtags, setStoryHashtags] = useState("")
    const [storyReferences, setStoryReferences] = useState("")


    const addStoryRequest = () => {
        var hashs = storyHashtags.split(',')
        var reflist = storyReferences.split(',')
        dispatch(addStory(storyDate, dateRef, storyTitle, storySummary, field, hashs, reflist, keyPeople))
    }
  

    useEffect(() => {
       
        dispatch(loadStoryline())

    }, [])


    const navigateTo = (section_in) => {
        history.push(`/admin3123/`+section_in)
    }



    return(



        <div style={{marginTop:'0px'}}>

            <p className='h3'>Storyline</p>

            <Row>
                <Col>
                <Form.Control type='text' placeholder='Date shown' value={storyDate} onChange={(e) => setStoryDate(e.target.value)} style={{margin:'5px'}}> 
            </Form.Control>
                </Col>
                <Col>
                <Form.Control type='date' placeholder='Date' value={dateRef} onChange={(e) => setDateRef(e.target.value)} style={{margin:'5px'}}> 
            </Form.Control>
                </Col>
            </Row>
        
            

            
                    
            <Form.Control type='text' placeholder='Title' value={storyTitle} onChange={(e) => setStoryTitle(e.target.value)} style={{margin:'5px'}}> 
            </Form.Control>
                    
            <Form.Control as='textarea' placeholder='Summmary' value={storySummary} onChange={(e) => setStorySummary(e.target.value)} style={{margin:'5px'}}> 
            </Form.Control>

            <Form.Control type='text' placeholder='field' value={field} onChange={(e) => setField(e.target.value)} style={{margin:'5px'}}> 
            </Form.Control>

            <Form.Control type='text' placeholder='Key People' value={keyPeople} onChange={(e) => setKeyPeople(e.target.value)} style={{margin:'5px'}}> 
            </Form.Control>
                    
            <Form.Control type='text' placeholder='Hashtags' value={storyHashtags} onChange={(e) => setStoryHashtags(e.target.value)} style={{margin:'5px'}}> 
            </Form.Control>

            <Form.Control as='textarea' placeholder='References' value={storyReferences} onChange={(e) => setStoryReferences(e.target.value)} style={{margin:'5px'}}> 
            </Form.Control>
                    
            <Button onClick={()=>addStoryRequest()}>Add</Button>


            <hr />

            <table>
                <tbody>

                    <tr>
                        <td>
                            Date
                        </td>
                        <td>
                            Event
                        </td>
                        <td>
                            Key People
                        </td>
                        <td>
                            Short Story
                        </td>
                        <td style={{maxWidth:'10vw'}}>
                            References
                        </td>
                    </tr>


                    {stories && stories.map((story, index)=>(
                        <tr>
                            <td>{story.dateString}</td>
                            <td>{story.title}</td>
                            <td>{story.people}</td>
                            <td>{story.summary}</td>
                            <td style={{maxWidth:'10vw'}}>{story.references}</td>
                        </tr>
                    ))}


                </tbody>
            </table>

            




        </div>



    )


}

export default AdminStorylineScreen


