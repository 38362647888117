import React , {useState, useEffect,  Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'

import { Row, Col, Card, Button, Form,  OverlayTrigger, Tooltip, InputGroup, ListGroup, Badge } from 'react-bootstrap'

import Moment from 'react-moment';

import {updateCardItem} from '../../../actions/creatorCardActions'

import SimpleTableCell from './SimpleTableCell'
import SimpleTableHeaderCell from './SimpleTableHeaderCell'


export const SimpleTableItem = ({card_id, item_in, mode_in}) => {

    const dispatch = useDispatch()

    const userState = useSelector(state => state.userState)
    const {userInfo} = userState

    const [sessionMode, setSessionMode] = useState("")
    const [text, setText] = useState('')

    const [cellValue, setCellValue] = useState('')

    const [nrows, setNrows] = useState(2)
    const [ncols, setNcols] = useState(2)
    const [values, setValues] = useState({})
    const [theaders, setTHeaders] = useState({})

    const [selectedRow, setSelectedRow] = useState(-1)
    const [selectedCol, setSelectedCol] = useState(-1)

    const onSelectCell = (irow, icol) => {
        setSelectedRow(irow+1)
        setSelectedCol(icol+1)
    }



    const [viewMode, setViewMode] = useState('view')
    const toggleViewMode = () => {

        console.log("View toggle", viewMode)

        if(viewMode == 'view'){
            setViewMode('edit')
            setText(item_in.text)

            // setValues(item_in.table.values)
            // setNcols(item_in.table.nrows)
            // setNrows(item_in.table.ncols)

        }else{
            setViewMode('view')
        }
    }

   

    const updateRows = async() => {
        

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const url = `/api/creatorcards/items/table/rows/${card_id}`

        const { data } = await axios.post(url, { nrows},config)


        setNrows(parseInt(data))


    }

    const updateCols = async() => {
       

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const url = `/api/creatorcards/items/table/columns/${card_id}`

        const { data } = await axios.post(url, {ncols },config)

        setNcols(parseInt(data))


    }

   

    const updateCell = async(irow, icol, cvalue) => {
        
        

        //console.log("table ", ctable)

        setSelectedRow(-1)
        setSelectedCol(-1)

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const url = `/api/creatorcards/items/table/values/${card_id}`

        const { data } = await axios.post(url, { irow, icol, cvalue},config)


        var ctable = values
        ctable[irow.toString() + "," + icol.toString()] = cellValue
        setValues(ctable)


    }


    const updateHeaderCell = async(icol, cvalue) => {
        
        // setSelectedRow(-1)
        // setSelectedCol(-1)

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const url = `/api/creatorcards/items/table/headers/${card_id}`

        const { data } = await axios.post(url, {icol, cvalue},config)


        var ctable = theaders
        ctable[icol.toString()] = cvalue
        setTHeaders(ctable)

    }


    const loadCardTable = async() => {

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const url = `/api/creatorcards/items/table/${card_id}`

        const { data } = await axios.get(url,config)


        if(data && data.theaders){
            setTHeaders(data.theaders)
        }

        if(data && data.data){
            setValues(data.data)
        }
        
        if(data && data.ncols){
            setNcols(parseInt(data.ncols))
        }
        
        if(data && data.nrows){
            setNrows(parseInt(data.nrows))
        }


    }


    useEffect(() => {


        loadCardTable()

        if(item_in.text){
            setText(item_in.text)
        }
        
        
        
    }, [])

    const updateTextItem = () => {

        setViewMode('view')
        var newItem = item_in
        
        newItem.nrows = nrows
        newItem.ncols = ncols
        newItem.values = values

        dispatch(updateCardItem(card_id, newItem))

    }


    return (

        <>


            {mode_in == 'edit' && (
                <>
                    {/* <InputGroup>
                        <Badge style={{paddingTop:'2px', paddingBottom:'2px', backgroundColor:'#8c8d8f', color:'white', marginRight:'2px'}} onClick={()=>addRow()}>+ Rows</Badge>
                        <Badge style={{paddingTop:'2px', paddingBottom:'2px', backgroundColor:'#8c8d8f', color:'white', marginRight:'2px'}} onClick={()=>addCol()} >+ Cols</Badge>
                        <Badge style={{paddingTop:'2px', paddingBottom:'2px', backgroundColor:'#8c8d8f', color:'white', marginRight:'2px'}} onClick={()=>addRow()}>Header</Badge>
                        <Badge style={{paddingTop:'2px', paddingBottom:'2px', backgroundColor:'#8c8d8f', color:'white'}} onClick={()=>updateTextItem()} >Update</Badge>
                    </InputGroup> */}

                    <Row>
                        <Col>
                            <InputGroup>
                            <p>nRows</p>
                            <Form.Control type='text' placeholder='text' value={nrows} onChange={(e) => setNrows(e.target.value)} style={{backgroundColor:'white'}}> 
                            </Form.Control>
                            <Button onClick={()=>updateRows()}>+</Button>
                            </InputGroup>
                            
                        </Col>
                        <Col>

                            <InputGroup>
                            <p>nCols</p>
                            <Form.Control type='text' placeholder='text' value={ncols} onChange={(e) => setNcols(e.target.value)} style={{backgroundColor:'white'}}> 
                            </Form.Control>
                            <Button onClick={()=>updateCols()}>+</Button>
                            </InputGroup>
                            
                        </Col>
                    </Row>

                    <hr />
                </>
            )}

           

                <>
                    <p>{item_in.text}</p>

                    <table style={{marginTop:'2px'}}>


                        {Array(ncols).fill(0).map((val, index2) =>(
                            <>
                                {mode_in == 'view' ? (
                                    <td style={{minWidth:'10vw', border:'solid', borderWidth:'1px', backgroundColor:'#e9f0f5'}}>
                                 {theaders && theaders[index2.toString()] && (
                                     <p className='h4'>{theaders[index2.toString()]}</p>
                                 )}
                                </td> 
                                ): (
                                    <SimpleTableHeaderCell icol={index2} value={theaders[index2.toString()]} updateValue={updateHeaderCell} viewModeG={viewMode}/>
                                )}

                            </>
                            
                                     
                        ))}

                        {Array(nrows).fill(0).map((val, index1) =>(
                            <tr>

                                {Array(ncols).fill(0).map((val, index2) =>(

                                    <>
                                        {mode_in == 'view' ? (
                                            <>
                                                <td style={{minWidth:'10vw', border:'solid', borderWidth:'1px'}}>
                                                    <p style={{padding:'0px'}}>{values[index1.toString()+ "," + index2.toString()]}</p>
                                                </td>
                                               
                                            </>
                                        ): (
                                            <>

                                                <SimpleTableCell irow={index1} icol={index2} cellType={'text'} value={values[index1.toString()+ "," + index2.toString()]} updateValue={updateCell} viewModeG={viewMode}/>  

                                            </>
                                        )}
                                    
                                    
                                    </>
                                    
                                    
                                  
                                    // <td style={{minWidth:'10vw', border:'solid', borderWidth:'1px'}} onClick={()=>onSelectCell(index1, index2)}>

                                    //     {(selectedRow == index1+1 && selectedCol == index2+1 && mode_in == 'edit') ? (

                                    //             <>
                                    //                 <InputGroup>

                                    //                 <Form.Control type='text' placeholder='text' value={cellValue} onChange={(e) => setCellValue(e.target.value)} style={{backgroundColor:'white'}}> 
                                    //                 </Form.Control>
                                    //                 <Badge onClick={()=>updateCell(index1, index2)}>
                                    //                     <i className="fas fa-check" ></i>
                                    //                 </Badge>
                                                    
                                    //                 </InputGroup>
                                                    
                                                
                                    //             </>

                                    //     ) : (

                                    //         <>

                                    //         {values && values[index1.toString()+ "," + index2.toString()] ? (
                                            
                                    //             <p style={{padding:'0px'}}>{values[index1.toString()+ "," + index2.toString()]}</p>

                                            

                                    //             ) : (

                                    //                 <p></p>

                                    //             )}


                                    //         </>

                                    //     )}

                                        
                                        
                                    // </td>

                                ))}

                            </tr>
                        ))}

                    </table>

                    
                
                </>
                

            

                {/* <>
                {sessionMode == 'edit' && (
                    <InputGroup>
                    <Form.Control type='text' placeholder='text' value={text} onChange={(e) => setText(e.target.value)} style={{backgroundColor:'white'}}> 
                    </Form.Control>
                    <Badge>
                        <i className="fas fa-check" onClick={()=>updateTextItem()}></i>
                    </Badge>
                </InputGroup>
                    )}

                </>
                
            

                {sessionMode == 'edit' && (
                    <span>
                    <Badge style={{padding:'5px'}} onClick={()=>toggleViewMode()}><i className="fas fa-edit" ></i></Badge>
                    <Badge style={{padding:'5px'}} onClick={()=>deleteItem()}><i className="fas fa-trash" ></i></Badge>
                </span>
                )} */}
            
                

        </>

    )
}


export default SimpleTableItem
