import React , {useState, useEffect,  Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Row, Col, Card, Button, Form,  OverlayTrigger, Tooltip, InputGroup, ListGroup, Badge } from 'react-bootstrap'

import Moment from 'react-moment';

import {updateCardItem} from '../../../actions/creatorCardActions'

export const CardDoubts = ({card_id, doubts}) => {

    const dispatch = useDispatch()

    const [text, setText] = useState('')

    


    useEffect(() => {
        
       
    }, [])

    const selectDoubts = () => {

        

    }


    return (

        <>

            <Card style={{borderRadius:'10px', padding:'20px', marginTop:'10px'}}>
                <p>Doubts</p>

                <span>
                    {doubts && doubts.map((cdoubt, index)=>(
                        <span>{cdoubt}</span>
                    ))}
                </span>
            </Card>
            
            

        </>

    )
}


export default CardDoubts
