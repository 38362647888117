import axios from 'axios';
import { setAlert } from './alertActions';

import {

    ADD_CARD,
    CARDS_FAIL,
    CARD_LIST,
    DELETE_CARD,
    UPDATE_CARD_TITLE,

    ADD_CARD_ITEM,
    UPDATE_CARD_ITEM,
    DELETE_CARD_ITEM,

    UPDATE_VIDEO_CARD,

    ADD_QUESTION_TEST




} from '../constants/creatorcardConstants'



export const deleteCard = ( card_id ) => async (dispatch, getState) => {

    try{

        //const { userLogin: { userInfo }} = getState()

        const { userState: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const url = `/api/creatorcards/${card_id}`

        const { data } = await axios.delete(url,config)


        console.log("Deleting Card")

        dispatch({
            type: DELETE_CARD,
            payload: card_id
        })

        

    }catch (error){

        dispatch({
            type: CARDS_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })

    }

}



export const updateCardTitle = ( card_id, title ) => async (dispatch, getState) => {

    try{


        console.log("Updating Card Title", card_id, title)

        const { userState: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const url = `/api/creatorcards/title/${card_id}`

        const { data } = await axios.put(url, {title},config)


        dispatch({
            type: UPDATE_CARD_TITLE,
            payload: data
            //  {
            //     "_id":card_id,
            //     "title":title
            // }
        })

    }catch (error){

        dispatch({
            type: CARDS_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })

    }

}




export const addCardTextItem = ( card_id, text, textMode ) => async (dispatch, getState) => {

    try{


        console.log("Add Card Item")

        const { userState: { userInfo }} = getState()

        const config = {
            headers: {
                'Contexnt-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const url = `/api/creatorcards/items/text/${card_id}`

        const { data } = await axios.post(url, {text, textMode},config)

        console.log("Data down ", data)


        dispatch({
            type: ADD_CARD_ITEM,
            payload:data
            // payload: {
            //     "card_id":card_id,
            //     "item":data
            // }
        })

    }catch (error){

        dispatch({
            type: CARDS_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })

    }

}

export const updateVideoCard = (card_id, url, description) => async (dispatch, getState) => {
    try{


        console.log("Update Video Card")

        const { userState: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const url = `/api/creatorcards/items/video/${card_id}`

        const { data } = await axios.post(url, { url, description},config)



        dispatch({
            type: UPDATE_VIDEO_CARD,
            payload: data
        })

    }catch (error){

        dispatch({
            type: CARDS_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })

    }

}



export const updateCardItem = ( card_id, item_id, newItem ) => async (dispatch, getState) => {

    try{


        


        const { userState: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const url = `/api/creatorcards/items/text/${card_id}`

        var text = newItem.text

        console.log("Updating Card Item ", item_id, newItem,text)

        const { data } = await axios.put(url, { item_id, text},config)



        dispatch({
            type: UPDATE_CARD_ITEM,
            payload: {
                "card_id":card_id,
                "item_id":item_id,
                "item":newItem
            }
        })

    }catch (error){

        dispatch({
            type: CARDS_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })

    }

}



export const deleteCardItem = ( card_id, item_id ) => async (dispatch, getState) => {

    try{


        console.log("Delete Card Item")

        dispatch({
            type: DELETE_CARD_ITEM,
            payload: {
                "card_id":card_id,
                "item_id":item_id,
            }
        })

    }catch (error){

        dispatch({
            type: CARDS_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })

    }

}



//-------------- Test --------------



export const addQuestionTest = (card_id,question) => async (dispatch, getState) => {
    try{


        console.log("Add Question Test ", card_id, question)


        const { userState: { userInfo }} = getState()

        const config = {
            headers: {
                'Contexnt-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const url = `/api/creatorcards/items/test/${card_id}`

        const { data } = await axios.post(url, {question},config)


        dispatch({
            type: ADD_QUESTION_TEST,
            payload: {
                "card_id":card_id,
                "question":question
            }
        })

    }catch (error){

        dispatch({
            type: CARDS_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })

    }

}

