import React , {useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, Card, Form, Button, ListGroup, OverlayTrigger, Popover, Tooltip, Carousel, InputGroup, FormControl, Table } from 'react-bootstrap'

import {loginUser} from '../actions/userActions'

export const AdminLoginScreen = ({match, history}) => {

    
    const dispatch = useDispatch()

    const userState = useSelector(state => state.userState)
    const {userInfo} = userState


    const [newModel, setNewModel] = useState("") 


    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')


    const loginUserRequest = () => {
        dispatch(loginUser(email, password))
    }
  

    useEffect(() => {
        
        if(userInfo && userInfo.token){
            history.push(`/admin3123/home`)
        }


    }, [userInfo])





    return(



        <div style={{marginTop:'0px'}}>

                <InputGroup>
                
                <Form.Control type='email' placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)}> 
                </Form.Control>

                <Form.Control type='password' placeholder='password' value={password} onChange={(e) => setPassword(e.target.value)}> 
                </Form.Control>

                <Button onClick={()=>loginUserRequest()}>Log In</Button>
                
                </InputGroup>



        </div>



    )


}

export default AdminLoginScreen


