import { 
    LOAD_LECTURE_LIST,
    LECTURE_LIST_FAIL,

    LOAD_LECTURE_DETAIL,
    LECTURE_DETAIL_FAIL,

    LOAD_LECTURE_CARDS,
    LECTURE_CARDS_FAIL,



} from "../constants/lectureConstants"





 export const lecturelistReducer = (state = { lectures: []}, action) => {

    switch(action.type){

        case LOAD_LECTURE_LIST:
            return { loading: false, lectures: action.payload }

        case LECTURE_LIST_FAIL:
            return { loading: false, error: action.payload, lectures: state.lectures }

        default:
            return state
    }


}


export const lectureDetailReducer = (state = { lectureDet: {}}, action) => {

    switch(action.type){

        case LOAD_LECTURE_DETAIL:
            return { loading: false, lectureDet: action.payload }

        

        case LECTURE_DETAIL_FAIL:
            return { loading: false, error: action.payload, lectureDet: state.lectureDet }

        default:
            return state
    }


}

export const lectureCardsReducer = (state = { lcards: []}, action) => {

    switch(action.type){

        case LOAD_LECTURE_CARDS:
            return { loading: false, lcards: action.payload }

        case LECTURE_CARDS_FAIL:
            return { loading: false, error: action.payload, lcards: state.lcards }

        default:
            return state
    }


}