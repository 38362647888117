import React , {useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, Card, Form, Button, ListGroup, OverlayTrigger, Popover, Tooltip, Carousel, InputGroup, FormControl, Table } from 'react-bootstrap'

import Meta from '../components/Meta'

import {loadPages} from '../actions/librarypageActions'


export const NNLibraryScreen = ({match, history}) => {

    
    const dispatch = useDispatch()

    const lpagelist = useSelector(state => state.lpagelist)
    const {lpages} = lpagelist


   
    const [sectionType, setSectionType] = useState("nodes") 
  

    const navigateToPage = (pageURL) => {
        history.push(`/library/${pageURL}`)
    }

    const selectPageRequest = (module_in) => {
        setSectionType(module_in)
        dispatch(loadPages(module_in))
    }

    useEffect(() => {
       
        dispatch(loadPages(sectionType))

    }, [])





    return(



        <div style={{marginTop:'0px'}}>


            <Meta title={'AI Library'} description={'A library and repository of all things Deep Learning. Find modules and notes on different deep learning models, neural network layers, optimizers, loss functions. Find the relevant bits of code to get your application up and running.'}/>

            {/* <Card>
                <Card.Header>
                    <p className='h3' style={{textAlign:'center'}}>Coming Soon</p>
                </Card.Header>
            </Card> */}


            <p className='h4' style={{textAlign:'center'}}>AI Library</p>

            <ListGroup horizontal>
                <ListGroup.Item style={{borderTop:'None', borderBottom:'None', borderLeft:'None'}}>
                    <p className='h4' style={{textAlign:'center', color:'#6b0752'}}>Library</p>
                </ListGroup.Item>
                <ListGroup.Item onClick={()=>selectPageRequest("nodes")} style={{backgroundColor:sectionType=='nodes'?'black':'white', color:sectionType=='nodes'?'white':'black'}}>
                    <p className='h5'>Layers/Activation</p>
                </ListGroup.Item>
                <ListGroup.Item onClick={()=>selectPageRequest("lossfunctions")} style={{backgroundColor:sectionType=='lossfunctions'?'black':'white', color:sectionType=='lossfunctions'?'white':'black'}}>
                <p className='h5'>Loss Functions</p>
                </ListGroup.Item>
                <ListGroup.Item onClick={()=>selectPageRequest("optimizers")} style={{backgroundColor:sectionType=='optimizers'?'black':'white', color:sectionType=='optimizers'?'white':'black'}}>
                <p className='h5'>Optimization</p>
                </ListGroup.Item>
                <ListGroup.Item onClick={()=>selectPageRequest("models")} style={{backgroundColor:sectionType=='models'?'black':'white', color:sectionType=='models'?'white':'black'}}>
                <p className='h5'>Pre-Built Models</p>
                </ListGroup.Item>
                <ListGroup.Item onClick={()=>selectPageRequest("datasets")} style={{backgroundColor:sectionType=='datasets'?'black':'white', color:sectionType=='datasets'?'white':'black'}}>
                <p className='h5'>Datasets</p>
                </ListGroup.Item>
            </ListGroup>


            



            {/* <Row>
                {lpages && lpages.map((cpage, index)=>(
                    <Col style={{minWidth:'30vw', maxWidth:'31vw', minHeight:'30vh', maxHeight:'31vh', overflow:"scroll"}} onClick={()=>navigateToPage(cpage.url)}>
                    <Card>
                        
                        <Card.Title>{cpage.title}</Card.Title>
                        <Card.Img>

                        </Card.Img>
                        <Card.Body>
                            {cpage.description}
                        </Card.Body>

                    </Card>
                    </Col>
                ))}
            </Row> */}

            <ListGroup style={{border:'None'}}>
            {lpages && lpages.map((cpage, index)=>(
                <ListGroup.Item style={{padding:'0px', border:'None'}}>
                    <Link to={`/library/${cpage.url}`}>
                    <Card style={{padding:'10px'}}>
                        
                        <Card.Header>
                             <p className='h4'>{cpage.title}</p>   
                        </Card.Header>
                        <Card.Img>

                        </Card.Img>
                        <Card.Body>
                            {cpage.summary}
                        </Card.Body>

                    </Card>
                    </Link>
                    
                </ListGroup.Item>
            ))}
            </ListGroup>
            



    



        </div>



    )


}

export default NNLibraryScreen


