import React , {useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, Card, Form, Button, ListGroup, OverlayTrigger, Popover, Tooltip, Carousel, InputGroup, FormControl, Table, Badge } from 'react-bootstrap'

import {loadLectures} from '../actions/lectureActions'

import {loadCourseDetail} from '../actions/courseActions'

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile,
    deviceDetect
  } from "react-device-detect";

import Meta from '../components/Meta'

export const CourseDetailScreen = ({match, history}) => {

    
    const dispatch = useDispatch()

    const course_url = match.params.course_url

    // const lecturelist = useSelector(state => state.lecturelist)
    // const {lectures} = lecturelist

    const courseDetail = useSelector(state => state.courseDetail)
    const {courseDet} = courseDetail

    const [sectionType, setSectionType] = useState("basics") 
    const [newModel, setNewModel] = useState("") 


    const [viewMode, setViewMode] = useState("lectures")
    const toggleViewMode = (mode_in) => {
        setViewMode(mode_in)
    }


    const selectPageRequest = (module_in) => {
        setSectionType(module_in)
        //dispatch(loadLecturesAdmin(module_in))
    }

    const navigateToPage = (pageURL) => {
        history.push(`/lectures/${pageURL}`)
    }

    
    

  

    useEffect(() => {
        
        dispatch(loadCourseDetail(course_url))

        dispatch(loadLectures())

    }, [])





    return(



        <div style={{marginTop:'0px', minHeight:'90vh'}}>


            <Meta title={'AI Lectures and Classes'} description={'Find a comprehensive collection of lectures taught on deep Learning, neural networks, convolutional networks, transformers, large language models. Find lectures on the basic prerequisite topics like matrices, probability, differentiation. Also find lectures on Python programming and Deep Learning libraries like Pytorch and Tensorflow.'}/>


            <Card>
                <Card.Header>
                    {courseDet && (
                        <>
                        
                        <p className='h4'>Course : {courseDet.title}</p>

                        {courseDet.instructors && (
                            <p><span className='h5'>Instructor : </span><span className='h5'>{courseDet.instructors.toString()}</span></p>
                        )}
                        

                        <p><span>Course description :</span> <span className='h5'>{courseDet.description}</span></p>

                        

                        </>
                    )}
                  
                </Card.Header>
            </Card>

            <InputGroup>
                <Button style={{backgroundColor:'#6b0752', paddingTop:'10px', paddingBottom:'10px', marginRight:'2px'}} onClick={()=>toggleViewMode('lectures')}>Lectures</Button>
                <Button style={{backgroundColor:'#6b0752', paddingTop:'10px', paddingBottom:'10px', marginRight:'2px'}} onClick={()=>toggleViewMode('supplementary')}>Supplementary</Button>
            </InputGroup>


            {viewMode == 'lectures' && (
                <>
                   <ListGroup style={{border:'None'}}>
            {courseDet && courseDet.lectures && courseDet.lectures.map((lec, index)=>(
                <ListGroup.Item style={{padding:'0px', border:'None', marginBottom:'20px'}}>
                    <Link to={`/lectures/${lec.url}`}>
                    <Card style={{padding:'10px'}}>
                        
                        <Card.Header>
                             <p>
                                <span className='h5'>{lec.title}</span>
                                {lec.lectureisPosted == false && (
                                    <Badge style={{backgroundColor:'#6b0752', color:'white', padding:'10px', borderRadius:'10px', marginLeft:'10px'}}>Coming Soon</Badge>
                                )}
                                
                            </p>   
                        </Card.Header>
                        <Card.Img>

                        </Card.Img>
                        <Card.Body>
                            {lec.description}
                        </Card.Body>

                        

                        

                    </Card>
                    </Link>
                    
                </ListGroup.Item>
            ))}
            </ListGroup>
                </>
            )}

            {viewMode == 'supplementary' && (
                <>

                    <p className='h4'>Prerequisites and Supplementary Lectures</p>

                <ListGroup style={{border:'None'}}>
            {courseDet && courseDet.supplementary && courseDet.supplementary.map((lec, index)=>(
                <ListGroup.Item style={{padding:'0px', border:'None', marginBottom:'20px'}}>
                    <Link to={`/lectures/${lec.url}`}>
                    <Card style={{padding:'10px'}}>
                        
                        <Card.Header>
                             <p>
                                <span className='h5'>{lec.topic}</span>
                                {lec.lectureisPosted == false && (
                                    <Badge style={{backgroundColor:'#6b0752', color:'white', padding:'10px', borderRadius:'10px', marginLeft:'10px'}}>Coming Soon</Badge>
                                )}
                                
                            </p>   
                        </Card.Header>
                        <Card.Img>

                        </Card.Img>
                        <Card.Body>
                            {lec.description}
                        </Card.Body>

                        

                        

                    </Card>
                    </Link>
                    
                </ListGroup.Item>
            ))}
            </ListGroup>
                
                </>
            )}


             
            

         
    



        </div>



    )


}

export default CourseDetailScreen


