import React , {useState, useEffect,  Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Row, Col, Card, Button, Form,  OverlayTrigger, Tooltip, InputGroup, ListGroup, Badge } from 'react-bootstrap'

import Moment from 'react-moment';

import YouTube from 'react-youtube';


import {updateVideoCard} from '../../../actions/creatorCardActions'


import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile,
    deviceDetect
  } from "react-device-detect";


export const VideoItem = ({card_in, mode_in}) => {

    const dispatch = useDispatch()


    var [sessionMode, setSessionMode] = useState('')

    const [videoUrl, setVideoUrl] = useState("")
    const [description, setDescription] = useState("")


    var opts = {
        height: '400px',
        width: '650px',
        playerVars: {
          
          autoplay: 0,
        },
    };

    if(isMobile){

        opts = {
            width: '250px',
            height: '150px',
            playerVars: {
              
              autoplay: 0,
            },
          };

    }

   

    const updateVideoItem = () => {

        

        dispatch(updateVideoCard(card_in._id, videoUrl, description))
        

    }


    const onReady = (event) => {
        event.target.pauseVideo();
        console.log('On Ready')
    }

    const onStateChange = (event) => {
        console.log('On State Change')
    }

    const onPlay = (event) => {
        console.log('On Play : ',card_in.url)
        //trackView()
    }



    useEffect(() => {
        // setVideoUrl(card_in.url)
        // setDescription(card_in.description)

    }, [])


    return (

        <>
            

            {card_in && card_in.video && (
                <>
                    <ListGroup>
                        
                        <ListGroup.Item>
                        <YouTube videoId={card_in.url} opts={opts} onReady={onReady} onStateChange={onStateChange} onPlay={onPlay}  />

                        </ListGroup.Item>
                        <ListGroup.Item>

                            <p className='h5'>{card_in.description}</p>
                        </ListGroup.Item>
                    </ListGroup>

                </>
            )}

           

            

            {mode_in == 'edit' && (

                <>

                
                <InputGroup>
                <Form.Control type='text' placeholder='Youtube Video URL' value={videoUrl} onChange={(e) => setVideoUrl(e.target.value)} style={{backgroundColor:'white'}}> 
                </Form.Control>

                <Badge>
                    <i className="fas fa-check" onClick={()=>updateVideoItem()}></i>
                </Badge>
                </InputGroup>

                <Form.Control as='textarea' placeholder='Add a Video Description' value={description} onChange={(e) => setDescription(e.target.value)} style={{backgroundColor:'white'}}> 
                </Form.Control>


                </>

            )}

                

        </>

    )
}


export default  VideoItem
