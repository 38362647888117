import { 
    LOAD_COURSE_LIST,
    COURSE_LIST_FAIL,
    LOAD_COURSE_DETAIL,
    COURSE_DETAIL_FAIL,

} from "../constants/courseConstants"





 export const courselistReducer = (state = { courses: []}, action) => {

    switch(action.type){

        case LOAD_COURSE_LIST:
            return { loading: false, courses: action.payload }

        case COURSE_LIST_FAIL:
            return { loading: false, error: action.payload, courses: state.courses }

        default:
            return state
    }


}


export const courseDetailReducer = (state = { courseDet: {}}, action) => {

    switch(action.type){

        case LOAD_COURSE_DETAIL:
            return { loading: false, courseDet: action.payload }


        case COURSE_DETAIL_FAIL:
            return { loading: false, error: action.payload, courseDet: state.courseDet }

        default:
            return state
    }


}
