import React , {useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, Card, Form, Button, ListGroup, OverlayTrigger, Popover, Tooltip, Carousel, InputGroup, FormControl, Table } from 'react-bootstrap'

import {addDictionaryWord, loadDictionary} from '../actions/creatorActions'

import DictionaryCard from '../components/dictionary/DictionaryCard'



export const AdminDictionaryScreen = ({match, history}) => {

    
    const dispatch = useDispatch()

    // const adminDictionary = useSelector(state => state.adminDictionary)
    // const {dictionary} = adminDictionary

    const dictionaryListR = useSelector(state => state.dictionaryListR)
    const {dictCards} = dictionaryListR


    const [newModel, setNewModel] = useState("") 

    const [storyDate, setStoryDate] = useState("")
    const [dateRef, setDateRef] = useState("")
    const [field, setField] = useState("")
    const [keyPeople, setKeyPeople] = useState("")
    const [storyTitle, setStoryTitle] = useState("")
    const [storySummary, setStorySummary] = useState("")
    const [storyHashtags, setStoryHashtags] = useState("")
    const [storyReferences, setStoryReferences] = useState("")


    // const addStoryRequest = () => {
    //     var hashs = storyHashtags.split(',')
    //     var reflist = storyReferences.split(',')
    //     dispatch(addDictionaryWord(storyDate, dateRef, storyTitle, storySummary, field, hashs, reflist, keyPeople))
    // }
  

    useEffect(() => {
       
        dispatch(loadDictionary())

    }, [])


    const navigateTo = (section_in) => {
        history.push(`/admin3123/`+section_in)
    }



    return(



        <div style={{marginTop:'0px'}}>

            <p className='h3'>Dictionary</p>

            {/* <Row>
                <Col>
                <Form.Control type='text' placeholder='Date shown' value={storyDate} onChange={(e) => setStoryDate(e.target.value)} style={{margin:'5px'}}> 
            </Form.Control>
                </Col>
                <Col>
                <Form.Control type='date' placeholder='Date' value={dateRef} onChange={(e) => setDateRef(e.target.value)} style={{margin:'5px'}}> 
            </Form.Control>
                </Col>
            </Row>
        
            
                    
            <Form.Control type='text' placeholder='Title' value={storyTitle} onChange={(e) => setStoryTitle(e.target.value)} style={{margin:'5px'}}> 
            </Form.Control>
                    
            <Form.Control as='textarea' placeholder='Summmary' value={storySummary} onChange={(e) => setStorySummary(e.target.value)} style={{margin:'5px'}}> 
            </Form.Control>

            <Form.Control type='text' placeholder='field' value={field} onChange={(e) => setField(e.target.value)} style={{margin:'5px'}}> 
            </Form.Control>

            <Form.Control type='text' placeholder='Key People' value={keyPeople} onChange={(e) => setKeyPeople(e.target.value)} style={{margin:'5px'}}> 
            </Form.Control>
                    
            <Form.Control type='text' placeholder='Hashtags' value={storyHashtags} onChange={(e) => setStoryHashtags(e.target.value)} style={{margin:'5px'}}> 
            </Form.Control>

            <Form.Control as='textarea' placeholder='References' value={storyReferences} onChange={(e) => setStoryReferences(e.target.value)} style={{margin:'5px'}}> 
            </Form.Control>
                    
            <Button onClick={()=>addStoryRequest()}>Add</Button>


            <hr />

            <ListGroup>
                {dictionary && dictionary.map((items, index)=>(
                    <ListGroup.Item>
                        {items.word} 
                        {items.meaning}
                    </ListGroup.Item>
                ))}
            </ListGroup>
             */}



            {dictCards && dictCards.map((dcard, index)=>(
                <DictionaryCard card_in={dcard}/>
            ))}




        </div>



    )


}

export default AdminDictionaryScreen


