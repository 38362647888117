import React , {useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, Card, Form, Button, ListGroup, OverlayTrigger, Popover, Tooltip, Carousel, InputGroup, FormControl, Table } from 'react-bootstrap'

import {loadLectureDetail, loadLectureQuiz} from '../actions/lectureActions'

import LectureMeta from '../components/admin/LectureMeta'
import LectureVideo from '../components/notecards/items/LectureVideo'
import QuizTake from '../components/quizzes/QuizTake'
import ViewCard from '../components/notecards/ViewCard'


import Meta from '../components/Meta'

export const LectureDetailScreen = ({match, history}) => {

    
    const dispatch = useDispatch()

    const lecture_id = match.params.lecture_id

    const lectureDetail = useSelector(state => state.lectureDetail)
    const {lectureDet} = lectureDetail

    const lectureCards = useSelector(state => state.lectureCards)
    const {lcards} = lectureCards


    const [viewMode, setViewMode] = useState("lecture") 
    const toggleViewMode = (mode_in) => {
        setViewMode(mode_in)

        // if(viewMode == 'notes'){
        //     setViewMode('quiz')
        // }else if(viewMode == 'quiz'){
        //     setViewMode('notes')
        // }
        // else if(viewMode == 'lecture'){
        //     setViewMode('notes')
        // }
    }
  

    useEffect(() => {
       dispatch(loadLectureDetail(lecture_id))
       //dispatch(loadLectureQuiz(lecture_id))

    }, [])





    return(



        <div style={{marginTop:'0px'}}>

            {lectureDet && lectureDet.title && (
                <Meta title={lectureDet.title} description={lectureDet.description}/>

            )}
            
            
            <Card>
                <Card.Header>
                {lectureDet && lectureDet.title && (
                <p className='h3' style={{textAlign:'center'}}>{lectureDet.title}</p>

            )}

                </Card.Header>
            </Card>

            


            
            

            <InputGroup>
                <Button style={{backgroundColor:'#6b0752', paddingTop:'10px', paddingBottom:'10px', marginRight:'2px'}} onClick={()=>toggleViewMode('lecture')}>Lecture</Button>
                <Button style={{backgroundColor:'#6b0752', paddingTop:'10px', paddingBottom:'10px', marginRight:'2px'}} onClick={()=>toggleViewMode('notes')}>Notes</Button>
                <Button style={{backgroundColor:'#6b0752', paddingTop:'10px', paddingBottom:'10px', marginRight:'2px'}} onClick={()=>toggleViewMode('quiz')}>Quiz</Button>
            </InputGroup>


            {viewMode == 'lecture' && (
                    <>
                      {lectureDet && lectureDet.lectureVideo && (
                            <>
                            <LectureVideo video_in={lectureDet.lectureVideo} mode_in='view'/>
                            </>
                        )}
                    
                    </>
                )}


                {viewMode == 'notes' && (
                    <>
                        {lcards && lcards.map((ccard, index)=>(
                            <ViewCard card_in={ccard} cindex={index}/>
                        ))}
                    
                    </>
                )}


                {viewMode == 'quiz' && (
                    <>
                        {lectureDet && lectureDet._id && (
                            <QuizTake lecture_id_in={lectureDet._id}/>
                        )}
                        
                    
                    </>
                )}
            


        </div>



    )


}

export default LectureDetailScreen


