import React , {useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, Card, Form, Button, ListGroup, OverlayTrigger, Popover, Tooltip, Carousel, InputGroup, FormControl, Table } from 'react-bootstrap'
import Meta from '../components/Meta'


import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile,
    deviceDetect
  } from "react-device-detect";



export const LandingScreen = ({match, history}) => {

    
    const dispatch = useDispatch()


    const [newModel, setNewModel] = useState("") 





    useEffect(() => {
        

    }, [])



    return(



        <div style={{marginTop:'0px'}}>


            <Meta title={'Pixagan.AI'} description={'Teaching you the fundamentals of Deep Learning and other Artifical Intelligence (AI) topics. We teach you the basic math topics that you will need to understand AI as well as the required Python coding. '}/>


            <section style={{backgroundColor:'#f0f2f2', marginLeft:'0px', marginRight:'0px', marginTop:'0px', padding:'20px'}}>

                <p className='h1'>AI and Machine Learning tech is growing at breakneck speed</p>
                {/* <p className='h1'>Your AI-Edu Station</p> */}
                <p className='h3'>Learn and stay up-to-date at</p>
               
               {/* <p className='h1'>Pixagan.AI</p> */}

               {isMobile ? (
                <img src="pixagan.png" alt="Pixagan Logo" style={{width:'20vw'}}/>
                ) : (
                <img src="pixagan.png" alt="Pixagan Logo" style={{width:'20vw'}}/>
                )}



               <p className='h3'>Courses on Deep Learning in English and Hindi</p>
            <Link to='courses/deep-learning-from-scratch'>
                <Button style={{borderRadius:'20px'}}>Start Learning</Button>

            </Link>

            </section>


        


            <section>

            
                <Link to='courses/deep-learning-from-scratch'>


                   <Card style={{padding:'20px', margin:'20px', marginTop:'5px', padingTop:'5px', border:'None'}}>
                   
                   <p className='h1'>Checkout our Deep Learning from Scratch Course</p>
                   
                   
                   <Row>
                        <Col>
                            {isMobile ? (
                            <img src="DL_Cover.jpg" alt="Neural Network Studio" style={{width:'30vw'}}/>
                            ) : (
                            <img src="DL_Cover.jpg" alt="Neural Network Studio" style={{width:'30vw'}}/>
                            )}
                        </Col>
                        <Col>
                        {isMobile ? (
                            <img src="Backprop_Cover.jpg" alt="Neural Network Studio" style={{width:'30vw'}}/>
                            ) : (
                            <img src="Backprop_Cover.jpg" alt="Neural Network Studio" style={{width:'30vw'}}/>
                            )}
                        </Col>
                    </Row>

                   </Card>

                   </Link>

                    <Card style={{padding:'20px', margin:'20px',backgroundColor:'#f0f2f2', border:'None'}}>
                    <Row>
                        <Col style={{paddingTop:'15vh'}}>
                        <p className='h1'>Understand the relevant coding languages</p>
                     
                        </Col>
                    <Col>
                        

                        {isMobile ? (
                            <img src="Python_Cover.jpg" alt="Neural Network Studio" style={{width:'30vw'}}/>
                            ) : (
                            <img src="Python_Cover.jpg" alt="Neural Network Studio" style={{width:'30vw'}}/>
                            )}
                        </Col>
                       
                        
                    </Row>

                    </Card>

                    
                    <Card style={{padding:'20px', margin:'20px', border:'None'}}>

                    <Row>
                    <Col style={{marginRight:'5px'}}>
                        
                        {isMobile ? (
                            <img src="ChainRule_Cover.jpg" alt="Neural Network Studio" style={{width:'30vw'}}/>
                            ) : (
                            <img src="ChainRule_Cover.jpg" alt="Neural Network Studio" style={{width:'30vw'}}/>
                            )}
                        </Col>
                        <Col style={{paddingTop:'15vh'}}>
                        <p className='h1'>Review all relevant Math Concepts</p>
                        </Col>
                    </Row>
                    </Card>
                   


                   

                    





                


            </section>


            {/* <section style={{backgroundColor:'#f0f2f2'}}>

            <p className='h1'>Supplementary Videos Library</p>
            <p className='h5'>Lectures and Notes that cover topics in Math, Coding and AI that can help you recap relevant topics that might be required for the main courses.</p>


            <Link to='/library'>
                <Button style={{borderRadius:'20px'}}>Check it out</Button>

            </Link>


            </section> */}


            {/* <section style={{backgroundColor:'#f0f2f2'}}>

                <p className='h1'>Checkout our Growing Deep Learning Library</p>
                <p className='h5'>Description, math and code on different nodes, models, algorithms</p>

             

                <Link to='/library'>
                    <Button style={{borderRadius:'20px'}}>Search for your topic of interest</Button>
                
                </Link>

            </section> */}

            {/* <section>

                <p className='h1'>Do you know what is the meaning of a Perceptron?</p>
                <p className='h5'>Refer to our AI dictionary to quickly grasp what a term means.</p>


            
                <Link to='/dictionary'>
                    <Button style={{borderRadius:'20px'}}>Dictionary</Button>
                
                </Link>


            </section> */}


            <section style={{backgroundColor:'#f0f2f2'}}>

                
                <p className='h1'>Follow the story from its origins and stay up to date with the latest innovations with Storyline.</p>

              

                <Link to='/storyline'>
                    <Button style={{borderRadius:'20px'}}>Storyline</Button>
                
                </Link>

            </section>

            {/* <section>

                <p className='h1'>Learning is an interactive and visual process that should involve experimentation.</p>
                <p className='h5'>Pixagan Apps are small tools that give you a no-code UI to experiment with different ideas and algorithms.</p>

             
                <Link to='/apps'>
                    <Button style={{borderRadius:'20px'}}>Apps</Button>
                
                </Link>

            </section> */}


            <section>
            {/* <section style={{backgroundColor:'#f0f2f2'}}></section> */}

                <p className='h1'>About Us</p>
                <p className='h5'>
                    Pixagan.AI has been created by Dr Anil Variyar with the objective of making it easy for everyone to understand and access the basics of AI, and more specifically, Deep Learning
                </p>
                
                <p className='h5'>
                    Anil has a PhD in Aeronautics and Astronautics from Stanford University. He has worked on Machine Learning and Deep Learning as part of his research and work over the years.
                </p>


            </section>


        </div>



    )


}

export default LandingScreen


