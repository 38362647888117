import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
//import { composeWithDevTools } from 'redux-devtools-extension'
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';


import {alertReducer} from './reducers/alertReducers'

import {userReducer} from './reducers/userReducers'

import {modelListReducer, modelDetailReducer, NNDetailReducer} from './reducers/modelReducers'

import {lpagelistReducer, librarypageDetailReducer, libraryCardsReducer} from './reducers/librarypageReducers'

import {adminpagelistReducer, adminpagedetailReducer, adminpagecardsReducer, adminlecturelistReducer, 
    adminlecturedetailReducer, adminaistorylineReducer, adminReferenceReducer,
    adminDictionaryReducer, adminQuizReducer, adminQuestionReducer, 
    adminDatasetReducer} from './reducers/creatorReducers'
 
import {languageReducer} from './reducers/languageReducers'

import {lecturelistReducer, lectureDetailReducer, lectureCardsReducer} from './reducers/lectureReducers'

import {aistorylineReducer, aiDictionaryReducer} from './reducers/storylineReducers'

import {quiztakeReducer} from './reducers/quizReducers.js'

import {dictionaryListReducer} from './reducers/dictionaryReducers.js'

import {courselistReducer, courseDetailReducer} from './reducers/courseReducers.js'

const reducer = combineReducers({

    alerts: alertReducer,

    modelList:modelListReducer, 
    modelDetail:modelDetailReducer,

    nnDetail: NNDetailReducer,

    lpagelist:lpagelistReducer, 
    librarypageDetail:librarypageDetailReducer,
    libraryCards: libraryCardsReducer,

    lecturelist:lecturelistReducer,
    lectureDetail:lectureDetailReducer, 
    lectureCards:lectureCardsReducer,

    aistoryline:aistorylineReducer, 
    aiDictionary:aiDictionaryReducer,

    quiztakeR:quiztakeReducer,

    courselist: courselistReducer, 
    courseDetail: courseDetailReducer,

    userState: userReducer,

    adminpagelist: adminpagelistReducer,
    adminpagedetail: adminpagedetailReducer,
    adminpagecards: adminpagecardsReducer,

    adminlecturelist:adminlecturelistReducer,
    adminlecturedetail:adminlecturedetailReducer,
    adminaistoryline:adminaistorylineReducer,
    adminReferenceR:adminReferenceReducer,

    adminDictionary: adminDictionaryReducer,
    adminDataset: adminDatasetReducer,
    dictionaryListR: dictionaryListReducer,

    adminQuiz: adminQuizReducer,
    adminQuestion: adminQuestionReducer,

    languageR:languageReducer,

   


})



const userInfoItemsFromStorage = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null

const initialState = {

    userState: { userInfo: userInfoItemsFromStorage },

} 

const middleware = [thunk]

const devTools =
  process.env.NODE_ENV === "production"
    ? applyMiddleware(...middleware)
    : composeWithDevTools(applyMiddleware(...middleware));

const store = createStore(reducer, initialState, devTools);


export default store