
import {
    LOAD_PAGELIST_ADMIN,
    PAGELIST_ADMIN_FAIL,
    CREATE_PAGE,

    LOAD_PAGE_DETAIL,
    PAGE_DETAIL_FAIL,

    LOAD_CARDS,
    

    LOAD_LECTURELIST_ADMIN,
    LECTURELIST_ADMIN_FAIL,
    CREATE_LECTURE,

    LOAD_LECTURE_DETAIL,
    LECTURE_DETAIL_FAIL,

    LOAD_STORYLIST_ADMIN,
    ADD_STORY,
    LOAD_STORYLIST_ADMIN_FAIL,

    UPDATE_LECTURE_VIDEO,
    UPDATE_LECTURE_VIDEO_FAIL,

    LOAD_REFERENCELIST_ADMIN,
    ADD_REFERENCE,
    LOAD_REFERENCELIST_ADMIN_FAIL,

    LOAD_DICTIONARY_ADMIN,
    ADD_DICTIONARY,
    LOAD_DICTIONARY_ADMIN_FAIL,

    LOAD_QUIZ_ADMIN,
    ADD_QUIZ,
    LOAD_QUIZ_ADMIN_FAIL,

    LOAD_QUESTIONS,
    ADD_QUIZ_QUESTION,
    UPDATE_QUIZ_QUESTION,
    DELETE_QUIZ_QUESTION,
    QUIZ_QUESTIONS_FAIL,

    LOAD_DATASET_ADMIN,
    ADD_DATASET,
    LOAD_DATASET_ADMIN_FAIL,


   
} from "../constants/creatorConstants"

import {

    ADD_CARD,
    CARDS_FAIL,
    CARD_LIST,
    DELETE_CARD,
    UPDATE_CARD_TITLE,

    ADD_CARD_ITEM,
    UPDATE_CARD_ITEM,
    DELETE_CARD_ITEM,

    UPDATE_VIDEO_CARD,

    ADD_QUESTION_TEST,
    CLEAR_CARDS

} from '../constants/creatorcardConstants'



 export const adminpagelistReducer = (state = { lpages: []}, action) => {

    switch(action.type){

        case LOAD_PAGELIST_ADMIN:
            return { loading: false, lpages: action.payload }

        case CREATE_PAGE:
            return { loading: false, error: action.payload, lpages: [action.payload, ...state.lpages] }

        case PAGELIST_ADMIN_FAIL:
            return { loading: false, error: action.payload, lpages: state.lpages }

        default:
            return state
    }

}



export const adminpagedetailReducer = (state = { pageDetail:''}, action) => {

    switch(action.type){

        case LOAD_PAGE_DETAIL:
            return { loading: false, pageDetail: action.payload}

        case PAGE_DETAIL_FAIL:
            return { loading: false, error: action.payload, pageDetail:state.pageDetail}

        default:
            return state
    }


}



export const adminpagecardsReducer = (state = { pcards: []}, action) => {

    switch(action.type){

        case LOAD_CARDS:
            return { loading: false, pcards: action.payload }


        case ADD_CARD:
            return { loading: false, pcards: [...state.pcards, action.payload] }




        case DELETE_CARD:
            return { loading: true, cards:state.pcards.filter(card => card._id != action.payload)}
        


        case UPDATE_VIDEO_CARD:
            return { loading: true, 
                pcards: state.pcards.map(card => card._id === action.payload._id ? { ...card,url: action.payload.url, description: action.payload.description} : card),
            }
        

        case ADD_CARD_ITEM:
            return { loading: true, pcards:state.pcards.map(card => card._id === action.payload._id ? { ...card, itemlist:action.payload.itemlist} : card)}
        

        case ADD_QUESTION_TEST:
            return { loading: true, 
                pcards: state.pcards.map(card => card._id === action.payload.card_id ? { ...card, questions:[...card.questions, action.payload.question]} : card)}



        case UPDATE_CARD_ITEM:
            return { loading: true, 
    
                pcards: state.pcards.map(card => card.id === action.payload.card_id ? { 
                    ...card, 
                        items:
                        [...card.items.map( item => item._id === action.payload.item_id ?{ ...item, 
                            text:action.payload.text
                        }: item

                        ),
                        ]
                }:card)
            
            }


        case DELETE_CARD_ITEM:
            return { loading: true, 
                pcards:state.pcards.map(card => card._id === action.payload.card_id ? { ...card, items:card.items.filter(item=>item._id != action.payload.item_id )} : card)
            }
    
        

        case UPDATE_CARD_TITLE:
            return { loading: true, 
                pcards: state.pcards.map(card => card._id === action.payload._id ? { ...card, title: action.payload.title} : card), 
            }
        


        
        case CLEAR_CARDS:
            return { loading: true, pcards:[]}
            



        case CARDS_FAIL:
            return { loading: false, error: action.payload, pcards: state.pcards }

        default:
            return state
    }


}




export const adminlecturelistReducer = (state = { lectures: []}, action) => {

    switch(action.type){

        case LOAD_LECTURELIST_ADMIN:
            return { loading: false, lectures: action.payload }


        case CREATE_LECTURE:
            return { loading: false, error: action.payload, lectures: [action.payload, ...state.lectures] }


        case LECTURELIST_ADMIN_FAIL:
            return { loading: false, error: action.payload, lectures: state.lectures }

        default:
            return state
    }


}





export const adminlecturedetailReducer = (state = { pageDetail:{}}, action) => {

    switch(action.type){

        case LOAD_LECTURE_DETAIL:
            return { loading: false, pageDetail: action.payload}

        case UPDATE_LECTURE_VIDEO:
            return { loading: false, pageDetail: action.payload }

        case LECTURE_DETAIL_FAIL:
            return { loading: false, error: action.payload, pageDetail:state.pageDetail}

        default:
            return state
    }


}


//Quiz

export const adminlecturequizReducer = (state = { quiz:{}}, action) => {

    switch(action.type){

        case LOAD_LECTURE_DETAIL:
            return { loading: false, pageDetail: action.payload}

        case UPDATE_LECTURE_VIDEO:
            return { loading: false, pageDetail: action.payload }

        case LECTURE_DETAIL_FAIL:
            return { loading: false, error: action.payload, pageDetail:state.pageDetail}

        default:
            return state
    }


}








export const adminaistorylineReducer = (state = { stories: []}, action) => {

    switch(action.type){

        case LOAD_STORYLIST_ADMIN:
            return { loading: false, stories: action.payload }

        case ADD_STORY:
            return { loading: false, stories: [action.payload, ...state.stories] }

        case LOAD_STORYLIST_ADMIN_FAIL:
            return { loading: false, error: action.payload, stories: state.stories }

        default:
            return state
    }


}



export const adminReferenceReducer = (state = { references: []}, action) => {

    switch(action.type){

        case LOAD_REFERENCELIST_ADMIN:
            return { loading: false, references: action.payload }

        case ADD_REFERENCE:
            return { loading: false, references: [action.payload, ...state.references] }

        case LOAD_REFERENCELIST_ADMIN_FAIL:
            return { loading: false, error: action.payload, references: state.references }

        default:
            return state
    }


}





export const adminDictionaryReducer = (state = { dictionary: []}, action) => {

    switch(action.type){

        case LOAD_DICTIONARY_ADMIN:
            return { loading: false, dictionary: action.payload }


        case ADD_DICTIONARY:
            return { loading: false, references: [action.payload, ...state.dictionary] }
    

        case LOAD_DICTIONARY_ADMIN_FAIL:
            return { loading: false, error: action.payload, dictionary: state.dictionary }

        default:
            return state
    }


}





export const adminQuizReducer = (state = { quiz: []}, action) => {

    switch(action.type){

        case LOAD_QUIZ_ADMIN:
            return { loading: false, quiz: action.payload }


        case ADD_QUIZ:
            return { loading: false, quiz: [action.payload, ...state.quiz] }
    

        case LOAD_QUIZ_ADMIN_FAIL:
            return { loading: false, error: action.payload, quiz: state.quiz }

        default:
            return state
    }


}




export const adminQuestionReducer = (state = { questions:[]}, action) => {

    switch(action.type){

        case LOAD_QUESTIONS:
            return { loading: false, questions: action.payload}

        case ADD_QUIZ_QUESTION:
            return { loading: false, questions: [action.payload, ...state.questions ] }

        case QUIZ_QUESTIONS_FAIL:
            return { loading: false, error: action.payload, questions:state.questions}

        default:
            return state
    }


}





export const adminDatasetReducer = (state = { datasets: []}, action) => {

    switch(action.type){

        case LOAD_DATASET_ADMIN:
            return { loading: false, datasets: action.payload }


        case ADD_DATASET:
            return { loading: false, datasets: [action.payload, ...state.datasets] }
    

        case LOAD_DATASET_ADMIN_FAIL:
            return { loading: false, error: action.payload, datasets: state.datasets }

        default:
            return state
    }


}

