import React , {useState, useEffect,  Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Row, Col, Card, Button, Form,  OverlayTrigger, Tooltip, InputGroup, ListGroup, Badge } from 'react-bootstrap'

import Moment from 'react-moment';

import CardTitle from './CardTitle'

import NoteItem from './items/NoteItem'
// import ExampleItem from './items/ExampleItem'
import VideoItem from './items/VideoItem'
import TestItem from './items/TestItem'
import ImageItem from './items/ImageItem'
import ListItem from './items/ListItem'
import SimpleTableItem from './items/SimpleTableItem'
import NNViewItem from './items/NNViewItem'
import CodeItem from './items/CodeItem'
import PlotItem from './items/PlotItem'

import CardDoubts from './items/CardDoubts'

// import TextItemMenu from './items/TextItemMenu'


export const EditCard = ({card_in, cindex}) => {

    const dispatch = useDispatch()



    useEffect(() => {
        


        
    }, [card_in])



    return (

        <Card style={{margin:'10px', padding:'5px', border:'solid',borderWidth:'1px'}}>
            <Card.Header style={{border:'None', margin:'3px', borderRadius:'10px'}}>

                {/* <InputGroup>
                    <Badge style={{border:'solid'}}>Edit</Badge>
                    <Badge style={{border:'solid'}}>Delete</Badge>
                </InputGroup> */}
                
                <CardTitle card_id={card_in._id} title_in={card_in.title} cardType={card_in.type} mode_in='edit'/>

            </Card.Header>  

            <Card.Body>



                {card_in.type == 'note' && (
                    <>
                        <NoteItem card_in={card_in} mode_in='edit'/>

                    </>
                )}

                {/* {card_in.type == 'example' && (
                    <>
                        <ExampleItem card_in={card_in}/>
                    </>
                )} */}

                {card_in.type == 'test' && (
                    <>
                        <TestItem card_in={card_in} mode_in='edit'/>
                    </>
                )}


                {card_in.type == 'video' && (
                    <>
                        <VideoItem item_in={card_in} mode_in='edit'/>    
                    </>
                )}

                {card_in.type == 'image' && (
                    <>
                        <ImageItem item_in={card_in} mode_in='edit'/>    
                    </>
                )} 


                {card_in.type == 'code' && (
                    <>
                        <CodeItem item_in={card_in} mode_in='edit'/>    
                    </>
                )}

                {card_in.type == 'nnview' && (
                    <>
                        <NNViewItem item_in={card_in} mode_in='edit'/>    
                    </>
                )}

                {card_in.type == 'list' && (
                    <>
                        <ListItem item_in={card_in} mode_in='edit'/>    
                    </>
                )}

                {card_in.type == 'table' && (
                    <>
                        <SimpleTableItem item_in={card_in} card_id={card_in._id} sessionMode='edit' mode_in='edit'/>    
                    </>
                )}

                {card_in.type == 'plot' && (
                    <>
                        <PlotItem item_in={card_in} card_id={card_in._id} sessionMode='edit' mode_in='edit'/>    
                    </>
                )}



                {card_in.doubts && (
                    <CardDoubts card_in={card_in} doubts={card_in.doubts} mode_in='edit'/>
                )}
                
            
                
            </Card.Body>          

        </Card>
    )
}


export default EditCard
