import {

    LOGIN_USER,
    LOGIN_FAIL,
    LOGOUT_USER

} from '../constants/userConstants.js';


export const userReducer = (state = { userInfo: null}, action) => {

    switch(action.type){
        case LOGIN_USER:
            return { loading: false, userInfo: action.payload }
        case LOGIN_FAIL:
            return { loading: false, error: action.payload, userInfo:state.user }
        case LOGOUT_USER:
            return { loading: false, userInfo:null }
        default:
            return state
    }

}

