import React , {useState, useEffect,  Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Row, Col, Card, Button, Form,  OverlayTrigger, Tooltip, InputGroup, ListGroup, Badge } from 'react-bootstrap'

import Moment from 'react-moment';

// import TextItemMenu from './TextItemMenu'

import TextItem from './TextItem'
import MathItem from './MathItem'
import TextlistItem from './TextlistItem'
import CodeTextItem from './CodeTextItem'
import PublicImageViewer from './PublicImageViewer'

import AddTextItem from '../forms/AddTextItem'


export const NoteItem = ({card_in, mode_in}) => {

    const dispatch = useDispatch()



    useEffect(() => {
        
        
    }, [])



    return (

        <>
        
            <ListGroup>
                {card_in.itemlist && card_in.itemlist.map((citem, index)=>(
                    <ListGroup.Item>

                        {citem.type == 'text' && (
                            <TextItem item_in={citem} card_id={card_in._id} item_id={index} mode_in={mode_in}/>
                        )}

                        
                        {citem.type == 'math' && (
                            <MathItem item_in={citem} card_id={card_in._id} item_id={index} mode_in={mode_in}/>
                        )}

                        {citem.type == 'list' && (
                            <TextlistItem item_in={citem} card_id={card_in._id} item_id={index} mode_in={mode_in}/>
                        )}

                        {/* {citem.type == 'table' && (
                            <TableItem item_in={citem} card_id={card_in._id} item_id={citem._id}/>
                        )}*/}


                        {citem.type == 'code' && (
                            <CodeTextItem item_in={citem} card_id={card_in._id} item_id={index} mode_in={mode_in}/>
                        )}


                        {citem.type == 'image' && (
                            <PublicImageViewer imageUrl={citem.text}/>
                        )} 

                    
                    </ListGroup.Item>

                ))}

                {mode_in == 'edit' && (
                    <ListGroup.Item style={{padding:'0px'}}>
                        <AddTextItem card_id={card_in._id}/>
                        
                    </ListGroup.Item>
                )}
                

            </ListGroup>


            {/* {sessionMode == 'edit' && (
                <TextItemMenu card_id={card_in._id}/>
            )} */}
            
        
        
        </>

    )
}


export default NoteItem
