import './App.css';

import React from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { Container } from 'react-bootstrap'
import PrivateRoute from './routing/PrivateRoute'

import TranslateWidget from './components/utils/TranslateWidget'; // Adjust the import path as needed


import StudioScreen from './screens/StudioScreen'
import LandingScreen from './screens/LandingScreen'

import CourseScreen from './screens/CourseScreen'
import CourseDetailScreen from './screens/CourseDetailScreen'


import LectureSeriesScreen from './screens/LectureSeriesScreen'
import LectureDetailScreen from './screens/LectureDetailScreen'
import NNLibraryScreen from './screens/NNLibraryScreen'
import LibraryPageScreen from './screens/LibraryPageScreen'
import SupplementaryLecturesScreen from './screens/SupplementaryLecturesScreen'


import PreModelsScreen from './screens/PreModelsScreen'
import StorylineScreen from './screens/StorylineScreen'
import DictionaryScreen from './screens/DictionaryScreen'

import RowNColScreen from './screens/Apps/RowNColScreen'
import ApplistScreen from './screens/ApplistScreen'
import OptimizerGameScreen from './screens/Apps/OptimizerGameScreen'
import SingleNodeModelScreen from './screens/Apps/SingleNodeModelScreen'


import TermsandConditionsScreen from './screens/TermsandConditionsScreen'
import PrivacyPolicyScreen from './screens/PrivacyPolicyScreen'

//Creator routes
import AdminHomeScreen from './screens/AdminHomeScreen'
import AdminLectureScreen from './screens/AdminLectureScreen'
import AdminLibraryScreen from './screens/AdminLibraryScreen'
import AdminPageCreatorScreen from './screens/AdminPageCreatorScreen'
import AdminLoginScreen from './screens/AdminLoginScreen'
import AdminLectureCreatorScreen from './screens/AdminLectureCreatorScreen'
import AdminGrowthTrackerScreeen from './screens/AdminGrowthTrackerScreen'

import AdminStorylineScreen from './screens/AdminStorylineScreen'
import AdminStoryScreen from './screens/AdminStoryScreen'

import AdminDatasetScreen from './screens/AdminDatasetScreen'
import AdminReferenceScreen from './screens/AdminReferenceScreen'

import AdminDictionaryScreen from './screens/AdminDictionaryScreen'

//Components
import Header from './components/layout/Header'
import Footer from './components/layout/Footer'
// import AlertComponent from './components/layout/AlertComponent'


const App = () => {

  return (

    <Router>

      <Header />

      <TranslateWidget />

      {/* <AlertComponent /> */}

      <main className='py-3' style={{marginTop:'0px', paddingTop:'0px'}}>

        <Switch>

          <Route path='/' component={LandingScreen} exact />
         
          <Route path='/courses' component={CourseScreen} exact />

          <Route path='/courses/:course_url' component={CourseDetailScreen} exact />


          <Route path='/lectures' component={LectureSeriesScreen} exact />

          <Route path='/lectures/:lecture_id' component={LectureDetailScreen} exact />

          <Route path='/library' component={NNLibraryScreen} exact />

          <Route path='/library/:page_id' component={LibraryPageScreen} exact />

  
          <Route path='/storyline' component={StorylineScreen} exact />


          <Route path='/termsandconditions' component={TermsandConditionsScreen} exact />
          
          <Route path='/privacypolicy' component={PrivacyPolicyScreen} exact />



          
          <Route path='/admin3123/login' component={AdminLoginScreen} exact />
          <PrivateRoute path='/admin3123/home' component={AdminHomeScreen} exact />
          <PrivateRoute path='/admin3123/lectures' component={AdminLectureScreen} exact />
          <PrivateRoute path='/admin3123/library' component={AdminLibraryScreen} exact />
          <PrivateRoute path='/admin3123/datasets' component={AdminDatasetScreen} exact />
          <PrivateRoute path='/admin3123/references' component={AdminReferenceScreen} exact />
          <PrivateRoute path='/admin3123/dictionary' component={AdminDictionaryScreen} exact />


          <PrivateRoute path='/admin3123/storyline' component={AdminStorylineScreen} exact />
          <PrivateRoute path='/admin3123/storyline/:story_url' component={AdminStoryScreen} exact />
          
          <PrivateRoute path='/admin3123/page/:page_url' component={AdminPageCreatorScreen} exact />
          <PrivateRoute path='/admin3123/lecture/:lecture_url' component={AdminLectureCreatorScreen} exact />
          <PrivateRoute path='/admin3123/growth' component={AdminGrowthTrackerScreeen} exact />




        {/* <Route path='/studio' component={StudioScreen} exact /> */}


          {/* <Route path='/models' component={PreModelsScreen} exact />*/}

          {/* <Route path='/dictionary' component={DictionaryScreen} exact />*/}

          {/* 
          <Route path='/apps' component={ApplistScreen} exact />
          <Route path='/apps/single-node-model' component={SingleNodeModelScreen} exact />
          <Route path='/apps/rowncol' component={RowNColScreen} exact />
          <Route path='/apps/two-dimensional-optimizer' component={OptimizerGameScreen} exact />
          */}



        </Switch>

      </main>

      <Footer />

    </Router>

  );
}

export default App;



