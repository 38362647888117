import axios from 'axios'
import {setAlert} from './alertActions';
import { 
    LOAD_LECTURE_LIST,
    LECTURE_LIST_FAIL,
    LOAD_LECTURE_DETAIL,
    LECTURE_DETAIL_FAIL,

    LOAD_LECTURE_CARDS,

    LOAD_QUIZ,
    LOAD_QUIZ_FAIL
} from "../constants/lectureConstants"

import {

    LOAD_QUIZ_BY_ID,
    LOAD_QUIZ_ERROR,
    CHECK_SOLUTION

} from '../constants/quizConstants'


export const loadLectures = () => async (dispatch, getState) => {
    try{


        const config = {
            headers: {
                'Content-Type': 'application/json',
                //Authorization: `Bearer ${userInfo.token}`
            }
        }

        const url = `/api/lectures`

        const { data } = await axios.get(url, config)


        console.log("Load Lectures ", data)

        dispatch({
            type: LOAD_LECTURE_LIST,
            payload: data
        })
        dispatch(setAlert('Lectures loaded Successfully loaded', 'success'));

    } catch(error){

        dispatch({
            type: LECTURE_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })


        dispatch(setAlert('Sorry unable to load Model', 'danger'));


    }
}





export const loadLectureDetail = (lecture_url) => async (dispatch, getState) => {
    try{


        const config = {
            headers: {
                'Content-Type': 'application/json',
                //Authorization: `Bearer ${userInfo.token}`
            }
        }

        const url = `/api/lectures/${lecture_url}`

        const { data } = await axios.get(url, config)


        console.log("Load Lectures ", data)

        dispatch({
            type: LOAD_LECTURE_DETAIL,
            payload: data.lecture
        })

        dispatch({
            type: LOAD_LECTURE_CARDS,
            payload: data.cards
        })
        dispatch(setAlert('Lectures loaded Successfully loaded', 'success'));

    } catch(error){

        dispatch({
            type: LECTURE_DETAIL_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })


        dispatch(setAlert('Sorry unable to load Model', 'danger'));


    }
}






export const loadLectureQuiz = (lecture_id) => async (dispatch, getState) => {
    try{


        const config = {
            headers: {
                'Content-Type': 'application/json',
                //Authorization: `Bearer ${userInfo.token}`
            }
        }

        const url = `/api/lectures/quiz/${lecture_id}`

        const { data } = await axios.get(url, config)


        console.log("Load Lectures ", data)

        dispatch({
            type: LOAD_QUIZ_BY_ID,
            payload: data
        })

       
        dispatch(setAlert('Quiz loaded Successfully loaded', 'success'));

    } catch(error){

        dispatch({
            type: LOAD_QUIZ_ERROR,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })


        dispatch(setAlert('Sorry unable to load Quiz', 'danger'));


    }
}
