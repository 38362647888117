import { 

    CREATE_DATASET,
    DATASETS_FAIL,
    DELETE_DATASET,
    EDIT_DATASET,
    LOAD_DATASETS,

    DATASET_DETAIL,
    DATASET_DETAIL_FAIL


 } from "../constants/dataConstants"


 import {
    LOAD_PAGELIST,
    PAGELIST_FAIL,

    LOAD_PAGE_DETAIL,
    PAGE_DETAIL_FAIL,

    LOAD_PAGE_CARDS,
    PAGE_CARDS_FAIL
} from "../constants/pageConstants"



 export const lpagelistReducer = (state = { lpages: []}, action) => {

    switch(action.type){

        case LOAD_PAGELIST:
            return { loading: false, lpages: action.payload }

        case PAGELIST_FAIL:
            return { loading: false, error: action.payload, lpages: state.datasets }

        default:
            return state
    }


}




 export const librarypageDetailReducer = (state = { pageDet:null}, action) => {

    switch(action.type){

        case LOAD_PAGE_DETAIL:
            return { loading: false, pageDet: action.payload }


        case PAGE_DETAIL_FAIL:
            return { loading: false, error: action.payload, pageDet: state.pageDet }

        default:
            return state
    }


}




export const libraryCardsReducer = (state = { pcards: []}, action) => {

    switch(action.type){

        case LOAD_PAGE_CARDS:
            return { loading: false, pcards: action.payload }

        case PAGE_CARDS_FAIL:
            return { loading: false, error: action.payload, pcards: state.pcards }

        default:
            return state
    }


}