import React , {useState, useEffect,  Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Row, Col, Card, Button, Form,  OverlayTrigger, Tooltip, InputGroup, ListGroup, Badge } from 'react-bootstrap'

import Moment from 'react-moment';

import QuestionItem from './QuestionItem'

import QuestionItemMenu from './QuestionItemMenu'


export const TestItem = ({card_in, mode_in}) => {

    const dispatch = useDispatch()

    const [questionList, setQuestionlist] = useState([])
    const [testInstructions, setTestInstructions] = useState([])

    const loadTestRequest = () => {


        setQuestionlist()
        setTestInstructions()

    }



    useEffect(() => {
        
            loadTestRequest(card_in.test_id)
    }, [])


    return (

        <>

            <div>

            </div>



            <div>

                <ListGroup>

                    {card_in.questionlist && card_in.questionlist.map((cquestion, index)=>(

                        <ListGroup.Item>

                            <QuestionItem card_id={card_in._id} question={cquestion}/>

                        </ListGroup.Item>

                    ))}
                

                        {mode_in == 'edit' && (
                            <ListGroup.Item>
                                <QuestionItemMenu card_id={card_in._id}/>
                            </ListGroup.Item>
                        )}
                        

                </ListGroup>


            </div>


            

            
        
        
        </>

        
    )
}


export default TestItem
