import axios from 'axios'
import {setAlert} from './alertActions';

import {
    LOAD_PAGELIST,
    PAGELIST_FAIL,

    LOAD_PAGE_DETAIL,
    PAGE_DETAIL_FAIL,

    LOAD_PAGE_CARDS,
    PAGE_CARDS_FAIL
} from "../constants/pageConstants"



export const loadPages = (page_type) => async (dispatch, getState) => {
    try{


        const config = {
            headers: {
                'Content-Type': 'application/json',
                //Authorization: `Bearer ${userInfo.token}`
            }
        }

        const url = `/api/nnlibrary?pagetype=${page_type}`

        const { data } = await axios.get(url, config)


        console.log("Load pagelist ", data)

        dispatch({
            type: LOAD_PAGELIST,
            payload: data
        })
        

    } catch(error){

        dispatch({
            type: PAGELIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })


      

    }
}




export const loadPageDetail = (page_id) => async (dispatch, getState) => {
    try{


        const config = {
            headers: {
                'Content-Type': 'application/json',
                //Authorization: `Bearer ${userInfo.token}`
            }
        }

        const url = `/api/nnlibrary/page/${page_id}`

        const { data } = await axios.get(url, config)


        console.log("Load Page ", data)

        dispatch({
            type: LOAD_PAGE_DETAIL,
            payload: data.pageDetail
        })


        dispatch({
            type: LOAD_PAGE_CARDS,
            payload: data.cards
        })
        

    } catch(error){

        dispatch({
            type: PAGE_DETAIL_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })


        


    }
}

