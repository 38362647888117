import React , {useState, useEffect,  Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {  useHistory} from 'react-router-dom'
import { Row, Col, Card, Button, Form,  OverlayTrigger, Tooltip, InputGroup, ListGroup, Badge } from 'react-bootstrap'

import Moment from 'react-moment';


export const DictionaryCard = ({card_in}) => {

    const dispatch = useDispatch()
    const history = useHistory();

    const [sessionMode, setSessionMode] = useState("")
    const [text, setText] = useState('')

    const [viewMode, setViewMode] = useState('view')
    const toggleViewMode = () => {

        console.log("View toggle", viewMode)

        if(viewMode == 'view'){
            setViewMode('edit')
           
        }else{
            setViewMode('view')
        }
    }

    const deleteItem = () => {

    }


    useEffect(() => {
        
    }, [])


    const linkTo = (citem) => {
        if(citem.type == "lecture"){
            history.push(`/lectures/${citem.url}`)
        }else if(citem.type == "library"){
            history.push(`/library/${citem.url}`)
        }
    }


    return (

        <>

            <Card>
                <Card.Header>
                    <p className='h4'>{card_in.term}</p>
                </Card.Header>

                <Card.Body>
                    {card_in.meaning && card_in.meaning.map((citem, index)=>(
                        <p>
                            {citem.text}
                        </p>
                    ))}

                </Card.Body>

                <Card.Body>
                    {card_in.related && card_in.related.map((citem, index)=>(
                        <Button onClick={()=>linkTo(citem)} style={{borderRadius:'20px', backgroundColor:"#8b008b", textAlign:'center', paddingTop:'2px', paddingBottom:'2px', marginTop:'5px'}}>
                            {citem.title}
                        </Button>
                    ))}
                </Card.Body>



            </Card>


               
                

        </>

    )
}


export default DictionaryCard
