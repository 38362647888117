import React , {useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, Card, Form, Button, ListGroup, OverlayTrigger, Popover, Tooltip, Carousel, InputGroup, FormControl, Table } from 'react-bootstrap'



import {loadPagesAdmin, addPage, loadLecturesAdmin, addLecture} from '../actions/creatorActions'

export const AdminLectureScreen = ({match, history}) => {

    
    const dispatch = useDispatch()

    const adminlecturelist = useSelector(state => state.adminlecturelist)
    const {lectures} = adminlecturelist


    const [sectionType, setSectionType] = useState("basics") 
    const [lectureTitle, setLectureTitle] = useState("") 


    const navigateToPage = (pageURL) => {
        history.push(`/admin3123/lecture/${pageURL}`)
    }

    const selectPageRequest = (module_in) => {
        setSectionType(module_in)
        dispatch(loadLecturesAdmin(module_in))
    }

    const createLectureRequest = () => {
        dispatch(addLecture(sectionType, lectureTitle))
    }

    useEffect(() => {
       
        dispatch(loadLecturesAdmin(sectionType))

    }, [])





    return(



        <div style={{marginTop:'0px'}}>

            {/* <p className='h4' style={{textAlign:'center'}}>Library</p> */}

            <ListGroup horizontal>
                <ListGroup.Item style={{borderTop:'None', borderBottom:'None', borderLeft:'None'}}>
                    <p className='h4' style={{textAlign:'center', color:'#6b0752'}}>Courses</p>
                </ListGroup.Item>
                <ListGroup.Item onClick={()=>selectPageRequest("mlbasics")} style={{backgroundColor:sectionType=='mlbasics'?'black':'white', color:sectionType=='mlbasics'?'white':'black'}}>
                    <p className='h5'>Machine Learning Basics</p>
                </ListGroup.Item>
                <ListGroup.Item onClick={()=>selectPageRequest("basics")} style={{backgroundColor:sectionType=='basics'?'black':'white', color:sectionType=='basics'?'white':'black'}}>
                    <p className='h5'>Deep Learning Basics</p>
                </ListGroup.Item>
                <ListGroup.Item onClick={()=>selectPageRequest("python")} style={{backgroundColor:sectionType=='python'?'black':'white', color:sectionType=='python'?'white':'black'}}>
                    <p className='h5'>Python for AI</p>
                </ListGroup.Item>
                <ListGroup.Item onClick={()=>selectPageRequest("optimization")} style={{backgroundColor:sectionType=='optimization'?'black':'white', color:sectionType=='optimization'?'white':'black'}}>
                    <p className='h5'>Optimization Basics</p>
                </ListGroup.Item>
                <ListGroup.Item onClick={()=>selectPageRequest("maths")} style={{backgroundColor:sectionType=='maths'?'black':'white', color:sectionType=='maths'?'white':'black'}}>
                    <p className='h5'>Math for AI</p>
                </ListGroup.Item>
                <ListGroup.Item onClick={()=>selectPageRequest("buildai")} style={{backgroundColor:sectionType=='buildai'?'black':'white', color:sectionType=='buildai'?'white':'black'}}>
                    <p className='h5'>Building AI Apps</p>
                </ListGroup.Item>
                <ListGroup.Item onClick={()=>selectPageRequest("useai")} style={{backgroundColor:sectionType=='useai'?'black':'white', color:sectionType=='useai'?'white':'black'}}>
                    <p className='h5'>Using AI Tools</p>
                </ListGroup.Item>
            </ListGroup>


            <Card>
                <Card.Header>

                    <InputGroup>
                    <Form.Control type='text' placeholder='Title' value={lectureTitle} onChange={(e) => setLectureTitle(e.target.value)} style={{backgroundColor:'white'}}> 
                    </Form.Control>

                    <Button onClick={()=>createLectureRequest()}>Create Lecture</Button>
                
                    </InputGroup>
                    
                </Card.Header>
            </Card>




            <Row>
                {lectures && lectures.map((clecture, index)=>(
                    <Col style={{minWidth:'30vw', maxWidth:'31vw', minHeight:'30vh', maxHeight:'31vh', overflow:"scroll"}} onClick={()=>navigateToPage(clecture._id)}>
                    <Card style={{border:'None'}}>
                        
                        <Card.Header>{clecture.title}</Card.Header>
                        <Card.Img>

                        </Card.Img>
                        <Card.Body>
                            {clecture.description}
                        </Card.Body>

                    </Card>
                    </Col>
                ))}
            </Row>
            


            

    



        </div>



    )


}

export default AdminLectureScreen


