import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';


import { useDispatch, useSelector } from 'react-redux'

import { Table, Button, Row, Col, Card, Form, Badge, Container, InputGroup, ListGroup } from 'react-bootstrap'


import {loadQuiz, addQuiz} from '../../actions/creatorActions'

import QuizQuestionEdit from './QuizQuestionEdit'
import AddQuestion from './AddQuestion'


const QuizCreator = ({ lecture_id }) => {


    const dispatch = useDispatch()

    const [title, setTitle] = useState('')

    const adminQuiz = useSelector(state => state.adminQuiz)
    const {quiz} = adminQuiz


    const adminQuestion = useSelector(state => state.adminQuestion)
    const {questions} = adminQuestion


    const createQuiz = () => {
        dispatch(addQuiz(lecture_id, title))
    }



    useEffect(() => {
        //load Quiz

        dispatch(loadQuiz(lecture_id))

    }, []);



    return (
    
        <div style={{margin:'1px'}}>

            <p className='h5'>Create the Quiz</p>

            {/* {quiz && quiz._id && (
                <Button onClick={()=>createQuiz()}>Create the Quiz</Button>
            
            )} */}

            {quiz && quiz.title && (
                <p>
                    {quiz.title}
                </p>
            )}

            <ListGroup>
            {questions && questions.map((question, index)=>(
                <ListGroup.Item>
                    
                    <QuizQuestionEdit quiz_id={quiz._id} question_in={question}/>

                </ListGroup.Item>
            ))}
            </ListGroup> 

            
            <AddQuestion quiz_id={quiz._id}/>
           
    


        </div>
    
    )
}


export default QuizCreator
