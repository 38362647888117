import React , {useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, Card, Form, Button, ListGroup, OverlayTrigger, Popover, Tooltip, Carousel, InputGroup, FormControl, Table } from 'react-bootstrap'


import {loadPageDetail} from '../actions/librarypageActions'

import ViewCard from '../components/notecards/ViewCard'

import Meta from '../components/Meta'

export const LibraryPageScreen = ({match, history}) => {

    
    const dispatch = useDispatch()

    const page_id = match.params.page_id

    // const librarypage = useSelector(state => state.librarypage)
    // const {pcards, title} = librarypage


    const librarypageDetail = useSelector(state => state.librarypageDetail)
    const {pageDet} = librarypageDetail

    const libraryCards = useSelector(state => state.libraryCards)
    const {pcards} = libraryCards


    const [sectionType, setSectionType] = useState("nodes") 
  



    useEffect(() => {
       
        dispatch(loadPageDetail(page_id))

    }, [])





    return(

        <div style={{marginTop:'0px'}}>


            {pageDet && pageDet.title && (
                <Meta title={pageDet.title} description={pageDet.summary}/>

            )}


            {pageDet && pageDet.title && (
                <p className='h3' style={{textAlign:'center'}}>{pageDet.title}</p>

            )}


            {pageDet && pageDet.summary && (
                <p className='h5'>{pageDet.summary}</p>

            )}
           

           {pcards && pcards.map((ccard, index)=>(
                <ViewCard card_in={ccard} cindex={index}/>
            ))}
            


        </div>



    )


}

export default LibraryPageScreen


