import React , {useState, useEffect,  Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'

import { Row, Col, Card, Button, Form,  OverlayTrigger, Tooltip, InputGroup, ListGroup, Badge } from 'react-bootstrap'

import Moment from 'react-moment';



export const SimpleTableView = ({item_in}) => {

    const dispatch = useDispatch()

    
    const [cellValue, setCellValue] = useState('')

    const [text, setText] = useState('')
    const [nrows, setNrows] = useState(2)
    const [ncols, setNcols] = useState(2)
    const [values, setValues] = useState({})
    const [theaders, setTHeaders] = useState({})


    const loadCardTable = async() => {

    
        const config = {
            headers: {
                'Content-Type': 'application/json',
            }
        }

        const url = `/api/cards/table/${item_in._id}`

        const { data } = await axios.get(url,config)

        console.log("View Table ", data)


        if(data && data.theaders){
            setTHeaders(data.theaders)
        }

        if(data && data.data){
            setValues(data.data)
        }
        
        if(data && data.ncols){
            setNcols(parseInt(data.ncols))
        }
        
        if(data && data.nrows){
            setNrows(parseInt(data.nrows))
        }


    }


    useEffect(() => {


        loadCardTable()

        if(item_in.text){
            setText(item_in.text)
        }
        
        
        
    }, [])

    


    return (

        <>


        
                <>
                    <p>{item_in.text}</p>

                    <table style={{marginTop:'2px'}}>


                        {Array(ncols).fill(0).map((val, index2) =>(
                            <>
                               
                                <td style={{minWidth:'10vw', border:'solid', borderWidth:'1px', backgroundColor:'#e9f0f5'}}>
                                 {theaders && theaders[index2.toString()] && (
                                     <p className='h4'>{theaders[index2.toString()]}</p>
                                 )}
                                </td> 
                                

                            </>
                            
                                     
                        ))}

                        {Array(nrows).fill(0).map((val, index1) =>(
                            <tr>

                                {Array(ncols).fill(0).map((val, index2) =>(

                                    <>
                                            
                                        <td style={{minWidth:'10vw', border:'solid', borderWidth:'1px'}}>
                                            <p style={{padding:'0px'}}>{values[index1.toString()+ "," + index2.toString()]}</p>
                                        </td>
                                               
                                    </>
                                    

                                ))}

                            </tr>
                        ))}

                    </table>

                    
                
                </>
                

            
            
                

        </>

    )
}


export default SimpleTableView
