import {
    LOAD_STORYLINE ,
    LOAD_STORYLINE_FAIL,

    LOAD_DICTIONARY,
    LOAD_DICTIONARY_FAIL
} from "../constants/storylineConstants"


 export const aistorylineReducer = (state = { stories: []}, action) => {

    switch(action.type){

        case LOAD_STORYLINE:
            return { loading: false, stories: action.payload }

        case LOAD_STORYLINE_FAIL:
            return { loading: false, error: action.payload, stories: state.stories }

        default:
            return state
    }


}



export const aiDictionaryReducer = (state = { dictionary: []}, action) => {

    switch(action.type){

        case LOAD_DICTIONARY:
            return { loading: false, dictionary: action.payload }

        case LOAD_DICTIONARY_FAIL:
            return { loading: false, error: action.payload, dictionary: state.dictionary }

        default:
            return state
    }


}





// export const aieventbubbleReducer = (state = { story: []}, action) => {

//     switch(action.type){

//         case EVENT_STORY_DETAIL:
//             return { loading: false, story: action.payload }

//         case EVENT_STORY_DETAIL_FAIL:
//             return { loading: false, error: action.payload, story: state.story }

//         default:
//             return state
//     }


// }
