import React , {useState, useEffect,  Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'

import { Row, Col, Card, Button, Form,  OverlayTrigger, Tooltip, InputGroup, ListGroup, Badge } from 'react-bootstrap'

import Moment from 'react-moment';

import LineChart from '../../plotting/LineChart'

//Load data in csv format

export const PlotItem = ({item_in, mode_in}) => {

    const dispatch = useDispatch()

    const userState = useSelector(state => state.userState)
    const {userInfo} = userState

    var [title, setTitle] = useState('')
    var [plotType, setPlotType] = useState('line')
    var [pdata, setPData] = useState({})

    var [xval, setXVal] = useState([])
    var [y1val, setY1Val] = useState([])
    var [y2val, setY2Val] = useState([])
    var [y3val, setY3Val] = useState([])

    var [viewToggle, setViewToggle] = useState('')


    const loadCardPlot = async() => {

        const config = {
            headers: {
                'Content-Type': 'application/json',
                //Authorization: `Bearer ${userInfo.token}`
            }
        }

        const url = `/api/cards/plot/${item_in._id}`

        const { data } = await axios.get(url,config)


        if(data && data.title){
            setTitle(data.title)
        }

        if(data && data.plotType){
            setPlotType(data.plotType)
        }
        
        if(data && data.xdata){
            setXVal(data.xdata)
        }
        
        if(data && data.y1data){
            setY1Val(data.y1data)
        }


    }


    const updateXVals = () => {

    }


    const updateY1Vals = () => {
        
    }

    const updateY2Vals = () => {
        
    }

    const updateY3Vals = () => {
        
    }


    useEffect(() => {
        
        loadCardPlot()

        
    }, [])



    return (

        <>

            {mode_in == 'edit' && (
                <>
                    <InputGroup>
                        <Button>Line</Button>
                    </InputGroup>

                </>
            )}



            {plotType == 'line' && (
                <>
                {xval.length >0 && y1val.length > 0 && (
                    <Card style={{maxWidth:'40vw'}}>
                    <LineChart xdata={xval} ydata={y1val} labels={['x vs A(x)']}/>
                    </Card>
                )}
                

                </>
                
                
            )}



            




        
        
        </>

    )
}


export default PlotItem
