import axios from 'axios';
import {

    LOGIN_USER,
    LOGIN_FAIL,
    LOGOUT_USER

} from '../constants/userConstants.js';


export const loginUser = (email, password) => async (dispatch, getState) => {
        
    try{

        //const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                //Authorization: `Bearer ${userInfo.token}`
            }
        }

        const res = await axios.post(`/api/users/login`, {email, password}, config)

        console.log("Login ", res.data)

        
        localStorage.setItem('userInfo', JSON.stringify(res.data))


        dispatch({
            type: LOGIN_USER,
            payload: res.data
        });
    } catch(err){
        dispatch({
            type: LOGIN_FAIL,
            payload: err.response && err.response.data.message ? err.response.data.message : err.message,
        });
    }
}




export const logoutUser = () => async (dispatch, getState) => {
        
    try{

        //const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                //Authorization: `Bearer ${userInfo.token}`
            }
        }

        //const res = await axios.post(`/api/users/login`, {email, password}, config)

        localStorage.setItem('userInfo', null)
        console.log("Cleared token")

        dispatch({
            type: LOGOUT_USER,
            payload: {}
        });

        
    } catch(err){
        dispatch({
            type: LOGIN_FAIL,
            payload: err.response && err.response.data.message ? err.response.data.message : err.message,
        });
    }
}