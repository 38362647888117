import React , {useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, Card, Form, Button, ListGroup, OverlayTrigger, Popover, Tooltip, Carousel, InputGroup, FormControl, Table } from 'react-bootstrap'

import {loadProjects, createProject} from '../actions/projectActions'

import DLHome from '../components/deeplearn/DLHome'
import {loadModels, createModel, loadModelById} from '../actions/modelActions'


export const StudioScreen = ({match, history}) => {

    
    const dispatch = useDispatch()

    const modelList = useSelector(state => state.modelList)
    const {models} = modelList


    const [newModel, setNewModel] = useState("") 


    const NeuronsList = [
        {
            
        }
    ]


    const selectCurrentModelRequest = (model_id) => {
        dispatch(loadModelById(model_id))
    }


    useEffect(() => {
        dispatch(loadModels())

    }, [])





    return(



        <div style={{marginTop:'0px'}}>

            <Row>
                <Col style={{overflow:'scroll'}}>
   
                    <DLHome />
            
                </Col>
            </Row>


        </div>



    )


}

export default StudioScreen


