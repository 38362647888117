import axios from 'axios';
import React , {useState, useEffect, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, Card, Form, Button, ListGroup, OverlayTrigger, Popover, Tooltip, Carousel, InputGroup, FormControl, Table, Radio, Badge } from 'react-bootstrap'

import {addQuizQuestion, updateQuizQuestion, deleteQuizQuestion} from '../../actions/creatorQuizActions'


export const AddQuestion = ({ quiz_id }) => {


  const dispatch = useDispatch()

  const userState = useSelector(state => state.userState)
  const { userInfo } = userState

  const [instructions, setInstructions] = useState('')
    
  const [qtext, setQText] = useState('')

  const [questionType, setQuestionType] = useState('mcq')

  const [nOptions, setnOptions] = useState(4)
    
  //const [options, setOptions] = useState([])

  const [option1, setOption1] = useState("")
  const [option2, setOption2] = useState("")
  const [option3, setOption3] = useState("")
  const [option4, setOption4] = useState("")
  const [option5, setOption5] = useState("")
  const [option6, setOption6] = useState("")

  const [optionin, setOptionin] = useState('')

  const [answer, setAnswer] = useState(-1)

  
  useEffect(() => {



  }, []);


  const submitQuestionRequest = () => {
    
      var options = [option1, option2, option3, option4, option5, option6]

      var options_trim = []
      for(var iV=0;iV<nOptions; iV++){
        options_trim.push(options[iV])
      }


      dispatch(addQuizQuestion(quiz_id, instructions, questionType, qtext, options_trim, answer))
    
   }




  
  return (

    <>
    
    <Card style={{padding:'20px', borderRadius:'20px', backgroundColor:'#f5f295', marginBottom:'20px'}}>


        <>

        <p>Create Question</p>


        <Form.Control type='text' placeholder='Instruction' value={instructions} onChange={(e) => setInstructions(e.target.value)}> 
        </Form.Control>


        <Card style={{margin:'10px', borderRadius:'5px'}}>
            <Card.Header style={{borderRadius:'5px'}}>

                <Form.Control type='text' placeholder='Question' value={qtext} onChange={(e) => setQText(e.target.value)}> 
                </Form.Control>


            </Card.Header>

        </Card>

        <Row>
          <Col>
          <Form.Control as='select' value={questionType} onChange={(e) => setQuestionType(e.target.value)} style={{margin:'5px'}}> 
          <option>Question Types</option>
          <option value="mcq">MCQ</option>
        </Form.Control>
          </Col>
          <Col>
          <Form.Control as='select' value={nOptions} onChange={(e) => setnOptions(e.target.value)} style={{margin:'5px'}}> 
          <option>N Options</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
        </Form.Control>
          </Col>
        </Row>



        <Row>
          <Col>
          <InputGroup>
        <Form.Control type='text' placeholder='Option' value={option1} onChange={(e) => setOption1(e.target.value)} style={{margin:'5px'}}> 
        </Form.Control>

        </InputGroup>
          </Col>
          <Col>
          <InputGroup>
        <Form.Control type='text' placeholder='Option' value={option2} onChange={(e) => setOption2(e.target.value)} style={{margin:'5px'}}> 
        </Form.Control>

        </InputGroup>
          </Col>
        </Row>


        <Row>
          <Col>
          
          <InputGroup>
        <Form.Control type='text' placeholder='Option' value={option3} onChange={(e) => setOption3(e.target.value)} style={{margin:'5px'}}> 
        </Form.Control>
        </InputGroup>
          </Col>
          <Col>
          <InputGroup>
        <Form.Control type='text' placeholder='Option' value={option4} onChange={(e) => setOption4(e.target.value)} style={{margin:'5px'}}> 
        </Form.Control>

        </InputGroup>
          </Col>
        </Row>

        <Row>
          <Col>
          
        <InputGroup>
        <Form.Control type='text' placeholder='Option' value={option5} onChange={(e) => setOption5(e.target.value)} style={{margin:'5px'}}> 
        </Form.Control>

        </InputGroup>
          </Col>
          <Col>
          <InputGroup>
        <Form.Control type='text' placeholder='Option' value={option6} onChange={(e) => setOption6(e.target.value)} style={{margin:'5px'}}> 
        </Form.Control>

        </InputGroup>
          </Col>
        </Row>


        <p>Ans : {answer}</p>
        <Form.Control as='select' value={answer} onChange={(e) => setAnswer(e.target.value)} style={{margin:'5px'}}> 
          <option>Select the Answer</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
        </Form.Control>




    
    </>


    <div>
    <Button onClick={()=>submitQuestionRequest()} style={{width:'25vw'}}>
        Add
    </Button>
    </div>

</Card>



    
    </>

  );
};

export default AddQuestion;
