import React , {useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, Card, Form, Button, ListGroup, OverlayTrigger, Popover, Tooltip, Carousel, InputGroup, FormControl, Table, Badge } from 'react-bootstrap'

import {loadLectures} from '../actions/lectureActions'

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile,
    deviceDetect
  } from "react-device-detect";

import Meta from '../components/Meta'

export const LectureSeriesScreen = ({match, history}) => {

    
    const dispatch = useDispatch()

    const lecturelist = useSelector(state => state.lecturelist)
    const {lectures} = lecturelist

    const [sectionType, setSectionType] = useState("basics") 
    const [newModel, setNewModel] = useState("") 


    const selectPageRequest = (module_in) => {
        setSectionType(module_in)
        //dispatch(loadLecturesAdmin(module_in))
    }

    const navigateToPage = (pageURL) => {
        history.push(`/lectures/${pageURL}`)
    }
    

  

    useEffect(() => {
        
        dispatch(loadLectures())

    }, [])





    return(



        <div style={{marginTop:'0px', minHeight:'90vh'}}>


            <Meta title={'AI Lectures and Classes'} description={'Find a comprehensive collection of lectures taught on deep Learning, neural networks, convolutional networks, transformers, large language models. Find lectures on the basic prerequisite topics like matrices, probability, differentiation. Also find lectures on Python programming and Deep Learning libraries like Pytorch and Tensorflow.'}/>


            <p className='h4' style={{textAlign:'center'}}>Your Deep Learning Edu-Station</p>

            {/* <Card>
                <Card.Header>
                    <p className='h3' style={{textAlign:'center'}}>Coming Soon</p>
                </Card.Header>
            </Card> */}


            <ListGroup horizontal>
                <ListGroup.Item style={{borderTop:'None', borderBottom:'None', borderLeft:'None'}}>
                    <p className='h4' style={{textAlign:'center', color:'#6b0752'}}>Courses</p>
                </ListGroup.Item>
                {/* <ListGroup.Item onClick={()=>selectPageRequest("mlbasics")} style={{backgroundColor:sectionType=='mlbasics'?'black':'white', color:sectionType=='mlbasics'?'white':'black'}}>
                    <p className='h5'>Machine Learning Basics</p>
                </ListGroup.Item> */}
                <ListGroup.Item onClick={()=>selectPageRequest("basics")} style={{backgroundColor:sectionType=='basics'?'black':'white', color:sectionType=='basics'?'white':'black'}}>
                    <p className='h5'>Deep Learning Basics</p>
                </ListGroup.Item>
                <ListGroup.Item onClick={()=>selectPageRequest("python")} style={{backgroundColor:sectionType=='python'?'black':'white', color:sectionType=='python'?'white':'black'}}>
                    <p className='h5'>Python for AI</p>
                </ListGroup.Item>
                <ListGroup.Item onClick={()=>selectPageRequest("maths")} style={{backgroundColor:sectionType=='maths'?'black':'white', color:sectionType=='maths'?'white':'black'}}>
                    <p className='h5'>Math for AI</p>
                </ListGroup.Item>
                {/* <ListGroup.Item onClick={()=>selectPageRequest("buildai")} style={{backgroundColor:sectionType=='buildai'?'black':'white', color:sectionType=='buildai'?'white':'black'}}>
                    <p className='h5'>Building AI Apps</p>
                </ListGroup.Item>
                <ListGroup.Item onClick={()=>selectPageRequest("useai")} style={{backgroundColor:sectionType=='useai'?'black':'white', color:sectionType=='useai'?'white':'black'}}>
                    <p className='h5'>Using AI Tools</p>
                </ListGroup.Item> */}
            </ListGroup>


            <ListGroup style={{border:'None'}}>
            {lectures && lectures.map((lec, index)=>(
                <ListGroup.Item style={{padding:'0px', border:'None', marginBottom:'20px'}}>
                    <Link to={`/lectures/${lec.url}`}>
                    <Card style={{padding:'10px'}}>
                        
                        <Card.Header>
                             <p>
                                <span className='h4'>{lec.title}</span>
                                {lec.lectureisPosted == false && (
                                    <Badge style={{backgroundColor:'#6b0752', color:'white', padding:'10px', borderRadius:'10px', marginLeft:'10px'}}>Coming Soon</Badge>
                                )}
                                
                            </p>   
                        </Card.Header>
                        <Card.Img>

                        </Card.Img>
                        <Card.Body>
                            {lec.description}
                        </Card.Body>

                        

                        

                    </Card>
                    </Link>
                    
                </ListGroup.Item>
            ))}
            </ListGroup>
            

    



        </div>



    )


}

export default LectureSeriesScreen


