import React , {useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, Card, Form, Button, ListGroup, OverlayTrigger, Popover, Tooltip, Carousel, InputGroup, FormControl, Table } from 'react-bootstrap'

import {addReferences, loadReferences} from '../actions/creatorActions'

export const AdminReferenceScreen = ({match, history}) => {

    
    const dispatch = useDispatch()

    const adminReferenceR = useSelector(state => state.adminReferenceR)
    const {references} = adminReferenceR


    const [referenceTitle, setReferenceTitle] = useState("") 
    const [referenceAuthors, setReferenceAuthors] = useState("")
    const [referenceURL, setReferenceURL] = useState("")
    const [referenceHashtags, setReferenceHashtags] = useState("")
    const [referenceSummary, setReferenceSummary] = useState("")
    const [topic, setTopic] = useState("")
    const [subtopic, setSubtopic] = useState("")


    useEffect(() => {
        dispatch(loadReferences())

    }, [])

    const addReferenceRequest = () => {
        dispatch(addReferences(referenceTitle, referenceAuthors, referenceURL, referenceHashtags, referenceSummary, topic, subtopic))
    }





    return(



        <div style={{marginTop:'0px'}}>

            <Card style={{border:'None'}}>

                <Form.Control type='text' placeholder='Title' value={referenceTitle} onChange={(e) => setReferenceTitle(e.target.value)} style={{margin:'5px'}}> 
                </Form.Control>

                <Form.Control type='text' placeholder='URL' value={referenceURL} onChange={(e) => setReferenceURL(e.target.value)} style={{margin:'5px'}}> 
                </Form.Control>

                <Form.Control type='text' placeholder='Authors' value={referenceAuthors} onChange={(e) => setReferenceAuthors(e.target.value)} style={{margin:'5px'}}> 
                </Form.Control>

                <Form.Control type='text' placeholder='Hashtags' value={referenceHashtags} onChange={(e) => setReferenceHashtags(e.target.value)} style={{margin:'5px'}}> 
                </Form.Control>

                <Form.Control type='text' placeholder='Topic' value={topic} onChange={(e) => setTopic(e.target.value)} style={{margin:'5px'}}> 
                </Form.Control>

                <Form.Control type='text' placeholder='Sub Topic' value={subtopic} onChange={(e) => setSubtopic(e.target.value)} style={{margin:'5px'}}> 
                </Form.Control>

                <Form.Control as='textarea' placeholder='Summary' value={referenceSummary} onChange={(e) => setReferenceSummary(e.target.value)} style={{margin:'5px'}}> 
                </Form.Control>

                <Button onClick={()=>addReferenceRequest()} style={{width:'15vw'}}>Add</Button>

            </Card>

    
            <ListGroup>
                {references && references.map((ref, index)=>(
                    <ListGroup.Item>
                        <Card style={{border:'None'}}>
                            <p>Title : {ref.title}</p>
                            <p>Authors : {ref.authors}</p>
                            <p>Summary : {ref.summary}</p>
                            <p>Url : {ref.url}</p>
                            <p>hashtags : {ref.hashtags.toString()}</p>
                        </Card>
                    </ListGroup.Item>
                ))}
                
            </ListGroup>


            




        </div>



    )


}

export default AdminReferenceScreen


