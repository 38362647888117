import React , {useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, Card, Form, Button, ListGroup, OverlayTrigger, Popover, Tooltip, Carousel, InputGroup, FormControl, Table } from 'react-bootstrap'


export const AdminStoryScreen = ({match, history}) => {

    
    const dispatch = useDispatch()

    const modelList = useSelector(state => state.modelList)
    const {models} = modelList


    const [newModel, setNewModel] = useState("") 


  

    useEffect(() => {
       

    }, [])


    const navigateTo = (section_in) => {
        history.push(`/admin3123/`+section_in)
    }



    return(



        <div style={{marginTop:'0px'}}>

    
         







        </div>



    )


}

export default AdminStoryScreen


