import axios from 'axios'
import {setAlert} from './alertActions';
import { 
    CREATE_MODEL,
    MODEL_FAIL,
    DELETE_MODEL,
    EDIT_MODEL,
    LOAD_MODELS,

    MODEL_DETAIL,
    MODEL_DETAIL_FAIL,

    NN_DETAIL,
    NN_ADD_LAYER,
    NN_UPDATE_LAYER
} from "../constants/modelConstants"



import {
    LOAD_PAGELIST_ADMIN,
    PAGELIST_ADMIN_FAIL,
    CREATE_PAGE,

    LOAD_PAGE_DETAIL,
    PAGE_DETAIL_FAIL,

    LOAD_CARDS,
    ADD_CARD,
    CARDS_FAIL,

    LOAD_LECTURELIST_ADMIN,
    LECTURELIST_ADMIN_FAIL,
    CREATE_LECTURE,

    LOAD_LECTURE_DETAIL,
    LECTURE_DETAIL_FAIL,

    LOAD_STORYLIST_ADMIN,
    ADD_STORY,
    LOAD_STORYLIST_ADMIN_FAIL,

    UPDATE_LECTURE_VIDEO,
    UPDATE_LECTURE_VIDEO_FAIL,

    LOAD_REFERENCELIST_ADMIN,
    ADD_REFERENCE,
    LOAD_REFERENCELIST_ADMIN_FAIL,

    LOAD_QUIZ_ADMIN,
    ADD_QUIZ,
    LOAD_QUIZ_ADMIN_FAIL,

    LOAD_QUESTIONS,
    ADD_QUIZ_QUESTION,
    UPDATE_QUIZ_QUESTION,
    DELETE_QUIZ_QUESTION,
    QUIZ_QUESTIONS_FAIL,

    LOAD_DATASET_ADMIN,
    ADD_DATASET,
    LOAD_DATASET_ADMIN_FAIL


   
} from "../constants/creatorConstants"


//const base_url = process.env.REACT_APP_PYBACKEND_URL


export const loadPagesAdmin = (page_type) => async (dispatch, getState) => {
    try{


        const { userState: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const url = `/api/creator/nnlibrary?pagetype=${page_type}`

        const { data } = await axios.get(url, config)


        console.log("Load Library ", data)

        dispatch({
            type: LOAD_PAGELIST_ADMIN,
            payload: data
        })
        dispatch(setAlert('Library loaded Successfully loaded', 'success'));

    } catch(error){

        dispatch({
            type: PAGELIST_ADMIN_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })


        dispatch(setAlert('Sorry unable to load Model', 'danger'));


    }
}




export const addPage = (sectionType, pageTitle) => async (dispatch, getState) => {
    try{

        const { userState: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const url = `/api/creator/nnlibrary`

        const { data } = await axios.post(url, {sectionType, pageTitle},config)


        console.log("Add Library Page ", data)

        dispatch({
            type: CREATE_PAGE,
            payload: data
        })
        dispatch(setAlert('Page Modelled Successfully loaded', 'success'));

    } catch(error){

        dispatch({
            type: PAGELIST_ADMIN_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })


        dispatch(setAlert('Sorry unable to load Model', 'danger'));


    }
}




export const loadPagesDetailAdmin = (page_id) => async (dispatch, getState) => {
    try{


        const { userState: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const url = `/api/creator/nnlibrary/${page_id}`

        const { data } = await axios.get(url, config)


        console.log("Load Library ", data)

        dispatch({
            type: LOAD_PAGE_DETAIL,
            payload: data.pageDetail
        })

        dispatch({
            type: LOAD_CARDS,
            payload: data.cards
        })


        dispatch(setAlert('Library loaded Successfully loaded', 'success'));

    } catch(error){

        dispatch({
            type: PAGELIST_ADMIN_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })


        dispatch(setAlert('Sorry unable to load Model', 'danger'));


    }
}




export const addCardPage = (page_id, cardType) => async (dispatch, getState) => {
    try{

        const { userState: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const url = `/api/creator/nnlibrary/${page_id}`

        const { data } = await axios.post(url, {cardType}, config)


        console.log("Add Card ", data)

        dispatch({
            type: ADD_CARD,
            payload: data
        })
        dispatch(setAlert('Card added successful', 'success'));

    } catch(error){

        dispatch({
            type: CARDS_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })


        dispatch(setAlert('Sorry unable to load Model', 'danger'));


    }
}




export const addCardItem = (page_id, card) => async (dispatch, getState) => {
    try{

        const { userState: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                //Authorization: `Bearer ${userInfo.token}`
            }
        }

        const url = `/api/mlais`

        const { data } = await axios.get(url, config)


        console.log("Load Projects ", data)

        dispatch({
            type: LOAD_MODELS,
            payload: data
        })
        dispatch(setAlert('AI Models loaded Successfully loaded', 'success'));

    } catch(error){

        dispatch({
            type: MODEL_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })


        dispatch(setAlert('Sorry unable to load Model', 'danger'));


    }
}

//---------------------------------------------------------
//Lectures

//
export const addLecture = (subject, lectureTitle) => async (dispatch, getState) => {
    try{

        const { userState: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const url = `/api/creator/lectures`

        const { data } = await axios.post(url, {subject, lectureTitle},config)


        console.log("Add Lecture", data)

        dispatch({
            type: CREATE_LECTURE,
            payload: data
        })
        dispatch(setAlert('Page Modelled Successfully loaded', 'success'));

    } catch(error){

        dispatch({
            type: LECTURELIST_ADMIN_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })


        dispatch(setAlert('Sorry unable to load Model', 'danger'));


    }
}




export const loadLecturesAdmin = (page_type) => async (dispatch, getState) => {
    try{


        const { userState: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const url = `/api/creator/lectures?pagetype=${page_type}`

        const { data } = await axios.get(url, config)


        console.log("Load Lectures ", data)

        dispatch({
            type: LOAD_LECTURELIST_ADMIN,
            payload: data
        })
        dispatch(setAlert('Lectures loaded Successfully loaded', 'success'));

    } catch(error){

        dispatch({
            type: LECTURELIST_ADMIN_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })


        dispatch(setAlert('Sorry unable to load Model', 'danger'));


    }
}



export const loadLectureDetailAdmin = (page_id) => async (dispatch, getState) => {
    try{


        const { userState: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const url = `/api/creator/lectures/${page_id}`

        const { data } = await axios.get(url, config)


        console.log("Load Lectures ", data)

        dispatch({
            type: LOAD_LECTURE_DETAIL,
            payload: data.lectureDetail
        })

        dispatch({
            type: LOAD_CARDS,
            payload: data.cards
        })

        dispatch(setAlert('Lecture loaded Successfully loaded', 'success'));

    } catch(error){

        dispatch({
            type: LECTURE_DETAIL_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })


        dispatch(setAlert('Sorry unable to load Lecture', 'danger'));


    }
}





export const updateLectureVideoAdmin = (lecture_id, video_url, description) => async (dispatch, getState) => {
    try{


        const { userState: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const url = `/api/creator/lectures/lectureVideo/${lecture_id}`

        const { data } = await axios.post(url, {video_url, description},config)


        console.log("Update Lectures URL", data)

        dispatch({
            type: UPDATE_LECTURE_VIDEO,
            payload: data.lectureDetail
        })


        dispatch(setAlert('Lecture loaded Successfully loaded', 'success'));

    } catch(error){

        dispatch({
            type: LECTURE_DETAIL_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })


        dispatch(setAlert('Sorry unable to load Lecture', 'danger'));


    }
}



//---------------------------------------
export const loadStoryline = () => async (dispatch, getState) => {
    try{

        const { userState: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const url = `/api/creator/storyline`

        const { data } = await axios.get(url, config)


        console.log("Story added", data)

        dispatch({
            type: LOAD_STORYLIST_ADMIN,
            payload: data
        })
        dispatch(setAlert('Story added Successfully loaded', 'success'));

    } catch(error){

        dispatch({
            type: LOAD_STORYLIST_ADMIN_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })


        dispatch(setAlert('Sorry unable to load Model', 'danger'));


    }
}




//
export const addStory = (storydate, dateRef, storyTitle, storySummary, field, storyHashtags, storyReferences, people) => async (dispatch, getState) => {
    try{

        const { userState: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const url = `/api/creator/storyline`

        const { data } = await axios.post(url, {storydate, dateRef, storyTitle, storySummary, field, storyHashtags, storyReferences, people},config)


        console.log("Story added", data)

        dispatch({
            type: ADD_STORY,
            payload: data
        })
        dispatch(setAlert('Story added Successfully loaded', 'success'));

    } catch(error){

        dispatch({
            type: LOAD_STORYLIST_ADMIN_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })

        dispatch(setAlert('Sorry unable to load Model', 'danger'));

    }
}




// ------------------------references ----------------------

export const addReferences = (title, authors, refurl, hashtags, summary, topic, subtopic) => async (dispatch, getState) => {
    try{

        const { userState: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const url = `/api/creator/references`

        const { data } = await axios.post(url, {title, authors, refurl, hashtags, summary, topic, subtopic},config)


        console.log("Reference added", data)

        dispatch({
            type: ADD_REFERENCE,
            payload: data
        })

       
    } catch(error){


        dispatch({
            type: LOAD_REFERENCELIST_ADMIN_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })
    
        dispatch(setAlert('Sorry unable to add Reference', 'danger'));

    }
}

export const loadReferences = () => async (dispatch, getState) => {
    try{

        const { userState: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const url = `/api/creator/references`

        const { data } = await axios.get(url, config)


        console.log("Story added", data)

        dispatch({
            type: LOAD_REFERENCELIST_ADMIN,
            payload: data
        })
        dispatch(setAlert('References added Successfully loaded', 'success'));

    } catch(error){

        dispatch({
            type: LOAD_REFERENCELIST_ADMIN_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })


        dispatch(setAlert('Sorry unable to load References', 'danger'));


    }
}



// ------------------------dictionary ----------------------

export const addDictionaryWord = (title, authors, refurl, hashtags, summary, topic, subtopic) => async (dispatch, getState) => {
    try{

        const { userState: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const url = `/api/creator/dictionary`

        const { data } = await axios.post(url, {title, authors, refurl, hashtags, summary, topic, subtopic},config)


        console.log("Reference added", data)

        dispatch({
            type: ADD_REFERENCE,
            payload: data
        })

       
    } catch(error){


        dispatch({
            type: LOAD_REFERENCELIST_ADMIN_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })
    
        dispatch(setAlert('Sorry unable to add Reference', 'danger'));

    }
}



export const loadDictionary = () => async (dispatch, getState) => {
    
    try{

        const { userState: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const url = `/api/creator/dictionary`

        const { data } = await axios.get(url, config)


        console.log("Story added", data)

        dispatch({
            type: LOAD_REFERENCELIST_ADMIN,
            payload: data
        })
        dispatch(setAlert('References added Successfully loaded', 'success'));

    } catch(error){

        dispatch({
            type: LOAD_REFERENCELIST_ADMIN_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })

        dispatch(setAlert('Sorry unable to load References', 'danger'));

    }
}






// ------------------------quiz ----------------------

export const addQuiz = (lecture_id, title) => async (dispatch, getState) => {
    try{

        const { userState: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const url = `/api/creator/quiz/${lecture_id}`

        const { data } = await axios.post(url, {title},config)


        console.log("Reference added", data)

        dispatch({
            type: ADD_QUIZ,
            payload: data
        })

       
    } catch(error){


        dispatch({
            type: LOAD_QUIZ_ADMIN_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })
    
        dispatch(setAlert('Sorry unable to add Reference', 'danger'));

    }
}





export const loadQuiz = (lecture_id) => async (dispatch, getState) => {
    
    try{

        const { userState: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const url = `/api/creator/quiz/${lecture_id}`

        const { data } = await axios.get(url, config)


        console.log("Load Quiz", data)

        dispatch({
            type: LOAD_QUIZ_ADMIN,
            payload: data.quizDetail
        })


        dispatch({
            type: LOAD_QUESTIONS,
            payload: data.questions
        })
        dispatch(setAlert('Quiz added Successfully loaded', 'success'));

    } catch(error){

        dispatch({
            type: LOAD_QUIZ_ADMIN_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })

        dispatch(setAlert('Sorry unable to load References', 'danger'));

    }
}





// ------------------------dataset ----------------------

export const addDataset = (title, description, dataType, dataurl, problem) => async (dispatch, getState) => {
    try{

        const { userState: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const url = `/api/creator/datasets`

        const { data } = await axios.post(url, {title, dataType, description, dataurl, problem},config)


        console.log("Dataset added", data)

        dispatch({
            type: ADD_DATASET,
            payload: data
        })

       
    } catch(error){


        dispatch({
            type: LOAD_DATASET_ADMIN_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })
    
        dispatch(setAlert('Sorry unable to add Dataset', 'danger'));

    }
}



export const loadDataset = () => async (dispatch, getState) => {
    
    try{

        const { userState: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const url = `/api/creator/datasets`

        const { data } = await axios.get(url, config)


        console.log("Story added", data)

        dispatch({
            type: LOAD_DATASET_ADMIN,
            payload: data
        })
        dispatch(setAlert('Dataset added Successfully loaded', 'success'));

    } catch(error){

        dispatch({
            type: LOAD_DATASET_ADMIN_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })

        dispatch(setAlert('Sorry unable to load Dataset', 'danger'));

    }
}


