import React , {useState, useEffect,  Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { Row, Col, Card, Button, Form,  OverlayTrigger, Tooltip, InputGroup, ListGroup, Badge } from 'react-bootstrap'

import Moment from 'react-moment';

import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';

import {updateCardItem} from '../../../actions/creatorCardActions'

export const CodeItem = ({item_in, mode_in}) => {

    const dispatch = useDispatch()

    const userState = useSelector(state => state.userState)
    const {userInfo} = userState

    const [viewMode, setViewMode] = useState("view")
    const [text, setText] = useState('')

    const codeString = '(num) => num + 1 \n i = i+1';

    const updateCodeText = async() => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const url = `/api/creatorcards/items/code/${item_in._id}`


        const { data } = await axios.post(url, {text}, config)

        setText(data.codeString)

        setViewMode('view')

    }

    useEffect(() => {

        setText(item_in.codeString)
         
    }, [])

   
    

    return (

        <>

           
                {mode_in == 'edit' && (
                    <>
                        <Button onClick={()=>setViewMode('edit')}>Edit</Button>
                    </>
                )}


                <>

                    {/* {item_in.codeString && ( */}
                        {viewMode == 'view' && (
                            <SyntaxHighlighter language="python">
                            {item_in.codeString}

                            {/* {text} */}
                        </SyntaxHighlighter>
                        )}
                        
                    {/* )} */}
                    

                    {/* <p>{item_in.text}</p> */}

                    
                </>
                

        

                <>
                {mode_in == 'edit' && viewMode == 'edit' && (
                    <InputGroup>
                    <Form.Control as='textarea' placeholder='text' rows={5} value={text} onChange={(e) => setText(e.target.value)} style={{backgroundColor:'white'}}> 
                    </Form.Control>
                    <Badge>
                        <i className="fas fa-check" onClick={()=>updateCodeText()}></i>
                    </Badge>
                </InputGroup>
                    )}

                </>
                
            

                
            
                

        </>

    )
}


export default CodeItem
