import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';


import { useDispatch, useSelector } from 'react-redux'

import { Table, Button, Row, Col, Card, Form, Badge, Container, InputGroup, ListGroup } from 'react-bootstrap'

import {addLayerNN} from '../../actions/modelActions'

import DeepLayersViz from './DeepLayersViz'

const DeepModel = ({ nnModel }) => {


    const dispatch = useDispatch()


    const [titleMode, setTitleMode] = useState('view')

    const [nnType, setNNType] = useState('MLP')

    const [startingModel, setStartingModel] = useState('')


    const [nInputs, setNInputs] = useState(1)
    const [nOutputs, setNOutputs] = useState(1)

    const [nNodes, setNNodes] = useState(3)


    const [nLayers, setNLayers] = useState(3)


    const [title, setTitle] = useState('')

    const [layerType, setLayerType] = useState('Select')

    const [layerIn, setLayerIn] = useState(0)

    const [layerOut, setLayerOut] = useState(0)

    const [params, setParams] = useState({})



    const addLayerRequest = () => {

        var isCompatible = checkCompatibility()
        if(isCompatible){
            dispatch(addLayerNN(nnModel._id, title, layerType, layerIn, layerOut, params))
        }
        
    }

    const FinalizeModelRequest = () => {
        //dispatch(finalizeModel(nnModel._id))
    }


    const checkCompatibility = () => {

    }

    const computeConnectivity = () => {

    }

    useEffect(() => {

    }, []);


    return (
    
        <div style={{margin:'1px'}}>

            {/* <InputGroup style={{marginTop:'10px'}}>
                <p className='h5' style={{textAlign:'center', paddingTop:'10px', marginRight:'20px'}}>Model</p>
                <Button onClick={()=>FinalizeModelRequest()} style={{paddingTop:'5px', paddingBottom:'5px', marginRight:'2px'}}>Lock Model</Button>
                <Button>Check Model</Button>
            </InputGroup> */}
            

            <Card style={{padding:'20px', margin:'10px'}}>

                <Row style={{borderBottom:'solid', marginBottom:'10px', paddingBottom:'10px'}}>
                    <Col>
                        <p>No of Input Nodes</p>
                        <Form.Control type='text' placeholder='1' value={nInputs} onChange={(e) => setNInputs(e.target.value)}> 
                        </Form.Control>
                    </Col>
                    <Col>
                        <p>No of Output Nodes</p>
                        <Form.Control type='text' placeholder='1' value={nOutputs} onChange={(e) => setNOutputs(e.target.value)}> 
                        </Form.Control>
                    </Col>

                </Row>

                <Row>
                    <Col>
                    <p>Select Layer Type</p>
                    <Form.Control as="select"  defaultValue={layerType} value={layerType} onChange={(e) => setLayerType(e.target.value)} >

                        <option value='select'>Select</option>
                        <option value='Linear'>Linear</option>
                        <option value='ReLU'>ReLU</option>
                        <option value='Sigmoid'>Sigmoid</option>
                        <option value='Tanh'>Tanh</option>

                    </Form.Control>
                    
                    </Col>

                    <Col>
                        <p>Num Nodes</p>
                        <Form.Control type='text' placeholder='Num Nodes' value={nNodes} onChange={(e) => setNNodes(e.target.value)}> 
                        </Form.Control>
                    
                    </Col>

                    {/* {layerType == 'Linear' && (
                        <>
                                            <Col>
                        <p>Connections In</p>
                        <Form.Control type='text' placeholder='In' value={layerIn} onChange={(e) => setLayerIn(e.target.value)}> 
                        </Form.Control>
                    
                    </Col>


                    <Col>
                        <p>Connections Out</p>
                        <Form.Control type='text' placeholder='Out' value={layerOut} onChange={(e) => setLayerOut(e.target.value)}> 
                        </Form.Control>
                    
                    </Col>
                        
                        </>
                    )} */}


                    <Col>
                        <p> Submit</p>
                        <Button onClick={()=>addLayerRequest()}>Add</Button>
                    </Col>
                    


                </Row>


            </Card>



                    
            {nnModel && nnModel.layers && (
                <>
                <DeepLayersViz layers={nnModel.layers} />
                </>
            )}

            
 

            





        </div>
    
    )
}


export default DeepModel
