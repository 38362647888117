import React , {useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, Card, Form, Button, ListGroup, OverlayTrigger, Popover, Tooltip, Carousel, InputGroup, FormControl, Table } from 'react-bootstrap'


import {loadPagesDetailAdmin, addCardPage, loadLectureDetailAdmin, addLecture} from '../actions/creatorActions'
import {EditCard} from '../components/notecards/EditCard'

import LectureMeta from '../components/admin/LectureMeta'
import LectureVideo from '../components/notecards/items/LectureVideo'


import QuizCreator from '../components/quizzes/QuizCreator'

export const AdminLectureCreatorScreen = ({match, history}) => {

    
    const dispatch = useDispatch()

    const lecture_url = match.params.lecture_url

    const adminlecturedetail = useSelector(state => state.adminlecturedetail)
    const {pageDetail} = adminlecturedetail

    const adminpagecards = useSelector(state => state.adminpagecards)
    const {pcards} = adminpagecards


    const [cardType, setCardType] = useState("text") 

    const [viewMode, setViewMode] = useState("notes")
    const updateViewMode = (mode_in) => {
        setViewMode(mode_in)
    }


  
    const addCardPageRequest = (card_in) => {

        dispatch(addCardPage(lecture_url, card_in))
    }

    const [editPageMeta, setEditPageMeta] = useState(false) 
    const toggleEditPageMeta = () => {
        setEditPageMeta(editPageMeta => editPageMeta = !editPageMeta)
    }




    useEffect(() => {
       
        dispatch(loadLectureDetailAdmin(lecture_url))

    }, [])





    return(



        <div style={{marginTop:'0px'}}>


            {pageDetail && pageDetail.title && (
                <>
                    <span className='h3' style={{textAlign:'center'}}>{pageDetail.title} | </span>
                    <i className="fas fa-edit" onClick={()=>toggleEditPageMeta()}></i>
                </>
                
            )}

            <hr />


            {/* <Card style={{borderRadius:'20px'}}>
                <Card.Header style={{borderRadius:'20px'}}>
                {pageDetail && pageDetail.description && (
                <>
                    <p className='h5'>Summary</p>
                    <p style={{textAlign:'center'}}>{pageDetail.description}</p>
                    
                </>
                
                )}
                </Card.Header>
            </Card> */}

            {pageDetail && pageDetail.lectureVideo && (
                <>
                    <LectureVideo lecture_id={pageDetail._id} video_in={pageDetail.lectureVideo} mode_in='edit'/>
                </>
            )}


            {editPageMeta && (
                <>
                    
                    <LectureMeta />
                
                </>
            )}
            
            

            <hr />


            <InputGroup>
                <Button onClick={()=>updateViewMode('notes')}>Notes</Button>
                <Button onClick={()=>updateViewMode('quiz')}>Quiz</Button>
                <Button onClick={()=>updateViewMode('connections')}>Connections</Button>
            </InputGroup>


            {viewMode == 'notes' && (
                <>
                 {pcards && pcards.map((pcard, index)=>(
                <>
                    <EditCard card_in={pcard} cindex={index}/>
                </>
            ))}

            <ListGroup horizontal>
                <ListGroup.Item onClick={()=>addCardPageRequest('note')}>Note</ListGroup.Item>
                <ListGroup.Item onClick={()=>addCardPageRequest('image')}>Image</ListGroup.Item>
                <ListGroup.Item onClick={()=>addCardPageRequest('code')}>Code</ListGroup.Item>
                <ListGroup.Item onClick={()=>addCardPageRequest('nnview')}>NNView</ListGroup.Item>
                <ListGroup.Item onClick={()=>addCardPageRequest('table')}>Table</ListGroup.Item>
                <ListGroup.Item onClick={()=>addCardPageRequest('plot')}>Plot</ListGroup.Item>
                <ListGroup.Item onClick={()=>addCardPageRequest('list')}>List</ListGroup.Item>
                <ListGroup.Item onClick={()=>addCardPageRequest('question')}>Question</ListGroup.Item>
                <ListGroup.Item onClick={()=>addCardPageRequest('video')}>Video</ListGroup.Item>
                <ListGroup.Item onClick={()=>addCardPageRequest('dataview')}>DataView</ListGroup.Item>
            </ListGroup>


                
                </>
            )}


            {viewMode == 'quiz' && (
                <>
                    <QuizCreator lecture_id={pageDetail._id}/>
                
                </>
            )}


            {viewMode == 'connection' && (
                <>
                
                
                </>
            )}


           

        </div>



    )


}

export default AdminLectureCreatorScreen


