import axios from 'axios';
import {

    LOAD_QUIZ_BY_ID,
    LOAD_QUIZ_ERROR,
    CHECK_SOLUTION

} from '../constants/quizConstants'



export const quiztakeReducer = (state = {quiz:null, questions:[]}, action) => {

    switch(action.type){

        case LOAD_QUIZ_BY_ID:
            return { loading: false, quiz:action.payload.quiz, questions:action.payload.questions  }

        case LOAD_QUIZ_ERROR:
            return { loading: false, error: action.payload, quiz:state.quiz, questions:state.questions }

        case CHECK_SOLUTION:
            return { loading: false, 
                quiz: state.quiz, 
                questions: state.questions.map(qs => qs._id === action.payload.question_id ? { ...qs, answer:action.payload.answer, correct:action.payload.ans, isAnswered:true, learn:action.payload.learn}:qs),
            }

        default:
            return state
    }


}