import axios from 'axios'
import {setAlert} from './alertActions';

import {
    LOAD_STORYLINE ,
    LOAD_STORYLINE_FAIL,

    LOAD_DICTIONARY,
    LOAD_DICTIONARY_FAIL

} from "../constants/storylineConstants"




export const loadStories = () => async (dispatch, getState) => {
    try{


        const config = {
            headers: {
                'Content-Type': 'application/json',
                //Authorization: `Bearer ${userInfo.token}`
            }
        }

        const url = `/api/storyline`

        const { data } = await axios.get(url, config)


        console.log("Load Storyline ", data)

        dispatch({
            type: LOAD_STORYLINE,
            payload: data
        })
        dispatch(setAlert('AI Stories loaded Successfully loaded', 'success'));

    } catch(error){

        dispatch({
            type: LOAD_STORYLINE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })


        dispatch(setAlert('Sorry unable to load Model', 'danger'));


    }
}







export const loadDictionary = () => async (dispatch, getState) => {
    try{


        const config = {
            headers: {
                'Content-Type': 'application/json',
                //Authorization: `Bearer ${userInfo.token}`
            }
        }

        const url = `/api/dictionary`

        const { data } = await axios.get(url, config)

        console.log("Load Storyline ", data)

        dispatch({
            type: LOAD_DICTIONARY,
            payload: data
        })
        dispatch(setAlert('AI Dictionary loaded Successfully loaded', 'success'));

    } catch(error){

        dispatch({
            type: LOAD_DICTIONARY_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })


        dispatch(setAlert('Sorry unable to load Model', 'danger'));


    }
}


