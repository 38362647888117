import React , {useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, Card, Form, Button, ListGroup, OverlayTrigger, Popover, Tooltip, Carousel, InputGroup, FormControl, Table } from 'react-bootstrap'


import {loadPagesDetailAdmin, addCardPage, loadLectureDetailAdmin, addLecture} from '../actions/creatorActions'
import {EditCard} from '../components/notecards/EditCard'

export const AdminPageCreatorScreen = ({match, history}) => {

    
    const dispatch = useDispatch()

    const page_url = match.params.page_url

    const adminpagedetail = useSelector(state => state.adminpagedetail)
    const {pageDetail} = adminpagedetail

    const adminpagecards = useSelector(state => state.adminpagecards)
    const {pcards} = adminpagecards


    const [cardType, setCardType] = useState("text") 

  
    const addCardPageRequest = (card_in) => {

        dispatch(addCardPage(page_url, card_in))
    }




    useEffect(() => {
       
        dispatch(loadPagesDetailAdmin(page_url))

    }, [])





    return(



        <div style={{marginTop:'0px'}}>


            {pageDetail && pageDetail.title && (
                <p className='h4' style={{textAlign:'center'}}>{pageDetail.title}</p>
            )}
            


            {pcards && pcards.map((pcard, index)=>(
                <>
                    <EditCard card_in={pcard} cindex={index}/>
                </>
            ))}

            <ListGroup horizontal>
                <ListGroup.Item onClick={()=>addCardPageRequest('note')}>Note</ListGroup.Item>
                <ListGroup.Item onClick={()=>addCardPageRequest('image')}>Image</ListGroup.Item>
                <ListGroup.Item onClick={()=>addCardPageRequest('code')}>Code</ListGroup.Item>
                <ListGroup.Item onClick={()=>addCardPageRequest('nnview')}>NNView</ListGroup.Item>
                <ListGroup.Item onClick={()=>addCardPageRequest('table')}>Table</ListGroup.Item>
                <ListGroup.Item onClick={()=>addCardPageRequest('plot')}>Plot</ListGroup.Item>
                <ListGroup.Item onClick={()=>addCardPageRequest('list')}>List</ListGroup.Item>
                <ListGroup.Item onClick={()=>addCardPageRequest('test')}>Test</ListGroup.Item>
                <ListGroup.Item onClick={()=>addCardPageRequest('video')}>Video</ListGroup.Item>
                <ListGroup.Item onClick={()=>addCardPageRequest('dataview')}>DataView</ListGroup.Item>
            </ListGroup>



        </div>



    )


}

export default AdminPageCreatorScreen


