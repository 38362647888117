import React from 'react';

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile,
    deviceDetect
  } from "react-device-detect";

const PublicImageViewer = ({ imageUrl }) => {
  return (
    <div>
      <img src={imageUrl} alt="Image" style={{ maxWidth:'100%', height: 'auto' }} />
    </div>
  );
};

export default PublicImageViewer;
