import axios from 'axios';
import React , {useState, useEffect, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, Card, Form, Button, ListGroup, OverlayTrigger, Popover, Tooltip, Carousel, InputGroup, FormControl, Table, Radio, Badge } from 'react-bootstrap'

import {addQuizQuestion, updateQuizQuestion, deleteQuizQuestion} from '../../actions/creatorQuizActions'

import QuizQuestion from './QuizQuestion'


export const QuizQuestionEdit = ({ quiz_id, question_in}) => {


  const dispatch = useDispatch()

  const userState = useSelector(state => state.userState)
  const { userInfo } = userState


  const [viewMode, setViewMode] = useState('view')

  const [instructions, setInstructions] = useState('')
    
  const [qtext, setQText] = useState('')

  const [questionType, setQuestionType] = useState('mcq')

  const [nOptions, setnOptions] = useState(4)
    
  //const [options, setOptions] = useState([])

  const [option1, setOption1] = useState("")
  const [option2, setOption2] = useState("")
  const [option3, setOption3] = useState("")
  const [option4, setOption4] = useState("")
  const [option5, setOption5] = useState("")
  const [option6, setOption6] = useState("")

  const [optionin, setOptionin] = useState('')

  const [answer, setAnswer] = useState(-1)

  const loadAnswer = async () => {

    const config = {
      headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`
      }
    }


    const full_url = `/api/creatorquiz/question/answer/${quiz_id}/${question_in._id}`

    const { data } = await axios.get(full_url,config)

    console.log("Answer ", data, data.answer)

    setAnswer(parseInt(data.answer)+1)


  }


  useEffect(() => {


        
        // loadAnswer()

        // setQText(question_in.question[0])

        // var nOptions = question_in.options.length
        // setnOptions(nOptions)


        // var coptions = question_in.options
        // for(var i=0; i<nOptions; i++){
        //     if(i==0){
        //         setOption1(coptions[i])
        //     }else if(i==1){
        //         setOption2(coptions[i])
        //     }else if(i==2){
        //         setOption3(coptions[i])
        //     }else if(i==3){
        //         setOption4(coptions[i])
        //     }else if(i==4){
        //         setOption5(coptions[i])
        //     }else if(i==5){
        //         setOption6(coptions[i])
        //     }
        // }


  }, []);


  const submitQuestionRequest = () => {
    
      var options = [option1, option2, option3, option4, option5, option6]

      var options_trim = []
      for(var iV=0;iV<nOptions; iV++){
        options_trim.push(options[iV])
      }


      dispatch(updateQuizQuestion(quiz_id, question_in._id, instructions, [qtext], options_trim, answer))
    
   }




  
  return (

    <>
    
    <Card style={{padding:'20px', borderRadius:'20px', marginBottom:'20px'}}>

        <InputGroup>
          <Button>View</Button>
          <Button>Edit</Button>
          <Button>Delete</Button>
        </InputGroup>


        {viewMode == 'view' && (
            <>

                <QuizQuestion quiz_id={quiz_id} question_in={question_in}/>


                {/* {question_in && question_in.Qtext && question_in.Qtext.map((cquestion, index)=>(
                    <p style={{fontWeight:'600'}}>{cquestion.item}</p>
                ))}


                {question_in && question_in.qoptions && question_in.qoptions.map((coption, index)=>(
                    <p>{coption.text}</p>
                ))} */}


            </>
        )}


        {viewMode == 'edit' && (
            <>


        <Form.Control type='text' placeholder='Instruction' value={instructions} onChange={(e) => setInstructions(e.target.value)}> 
        </Form.Control>


        <Card style={{margin:'10px', borderRadius:'5px'}}>
            <Card.Header style={{borderRadius:'5px'}}>

                <Form.Control type='text' placeholder='Question' value={qtext} onChange={(e) => setQText(e.target.value)}> 
                </Form.Control>


            </Card.Header>

        </Card>

        <Row>
          <Col>
          <Form.Control as='select' value={questionType} onChange={(e) => setQuestionType(e.target.value)} style={{margin:'5px'}}> 
          <option>Question Types</option>
          <option value="mcq">MCQ</option>
        </Form.Control>
          </Col>
          <Col>
          <Form.Control as='select' value={nOptions} onChange={(e) => setnOptions(e.target.value)} style={{margin:'5px'}}> 
          <option>N Options</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
        </Form.Control>
          </Col>
        </Row>



        <Row>
          <Col>
          <InputGroup>
        <Form.Control type='text' placeholder='Option' value={option1} onChange={(e) => setOption1(e.target.value)} style={{margin:'5px'}}> 
        </Form.Control>

        </InputGroup>
          </Col>
          <Col>
          <InputGroup>
        <Form.Control type='text' placeholder='Option' value={option2} onChange={(e) => setOption2(e.target.value)} style={{margin:'5px'}}> 
        </Form.Control>

        </InputGroup>
          </Col>
        </Row>


        <Row>
          <Col>
          
          <InputGroup>
        <Form.Control type='text' placeholder='Option' value={option3} onChange={(e) => setOption3(e.target.value)} style={{margin:'5px'}}> 
        </Form.Control>
        </InputGroup>
          </Col>
          <Col>
          <InputGroup>
        <Form.Control type='text' placeholder='Option' value={option4} onChange={(e) => setOption4(e.target.value)} style={{margin:'5px'}}> 
        </Form.Control>

        </InputGroup>
          </Col>
        </Row>

        <Row>
          <Col>
          
        <InputGroup>
        <Form.Control type='text' placeholder='Option' value={option5} onChange={(e) => setOption5(e.target.value)} style={{margin:'5px'}}> 
        </Form.Control>

        </InputGroup>
          </Col>
          <Col>
          <InputGroup>
        <Form.Control type='text' placeholder='Option' value={option6} onChange={(e) => setOption6(e.target.value)} style={{margin:'5px'}}> 
        </Form.Control>

        </InputGroup>
          </Col>
        </Row>


        <p>Ans : {answer}</p>
        <Form.Control as='select' value={answer} onChange={(e) => setAnswer(e.target.value)} style={{margin:'5px'}}> 
          <option>Select the Answer</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
        </Form.Control>




    
    


    <div>
    <Button onClick={()=>submitQuestionRequest()} style={{width:'25vw'}}>
        Update
    </Button>
    </div>


            </>
        )}



       

</Card>



    
    </>

  );
};

export default QuizQuestionEdit;
