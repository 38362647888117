import React , {useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, Card, Form, Button, ListGroup, OverlayTrigger, Popover, Tooltip, Carousel, InputGroup, FormControl, Table } from 'react-bootstrap'
import Meta from '../components/Meta'


import StorylineCard from '../components/stories/StorylineCard'

import {loadStories} from '../actions/storyActions'


import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile,
    deviceDetect
  } from "react-device-detect";


export const StorylineScreen = ({match, history}) => {

    
    const dispatch = useDispatch()


    const aistoryline = useSelector(state => state.aistoryline)
    const {stories} = aistoryline

    const [newModel, setNewModel] = useState("") 


    useEffect(() => {

        dispatch(loadStories())

    }, [])



    return(

        <div style={{marginTop:'0px'}}>

            <Meta title={'AI Storyline'} description={'Tracing the story of AI from its origins to the current state of the art.'}/>

            <p className='h1' style={{textAlign:'center'}}>The AI Storyline</p>

            {stories && stories.map((story, index)=>(

                <StorylineCard story_in={story}/>
            ))}


           

{/* 
            <Table style={{width:'98vw'}}>
                <tbody>

                    <tr>
                        <td style={{maxWidth:'5vw'}}>
                            Date
                        </td>
                        <td>
                            Event
                        </td>
                        <td style={{width:'40vw'}}>
                            Short Story
                        </td>
                        <td style={{width:'10vw'}}>
                            Key People
                        </td>
                        <td>
                            References
                        </td>
                        
                    </tr>


                    {stories && stories.map((story, index)=>(
                        <tr>
                            <td style={{maxWidth:'5vw'}}>{story.dateString}</td>
                            <td>{story.title}</td>
                            <td style={{minWidth:'40vw'}}>{story.summary}</td>
                            <td>{story.people}</td>
                            <td style={{maxWidth:'30vw', overflow:'scroll'}}>
                                
                                {story.references && story.references.map((cref, index)=>(

                                
                                    <a
                                    key={index}
                                    href={cref}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    >{cref}</a>
                            

                                ))}
                                
                                
                            </td>
                            
                        </tr>
                    ))}


                </tbody>
            </Table> */}


        </div>


    )


}

export default StorylineScreen


