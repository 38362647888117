import React , {useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, Card, Form, Button, ListGroup, OverlayTrigger, Popover, Tooltip, Carousel, InputGroup, FormControl, Table } from 'react-bootstrap'

import DataCard from '../components/dataview/DataCard'


import {addDataset, loadDataset} from '../actions/creatorActions'



export const AdminDatasetScreen = ({match, history}) => {

    
    const dispatch = useDispatch()

    const adminDataset = useSelector(state => state.adminDataset)
    const {datasets} = adminDataset


    const [title, setTitle] = useState("") 
    const [description, setDescription] = useState("") 
    const [dataType, setDataType] = useState("") 
    const [dataurl, setDataURL] = useState("") 
    const [problem, setProblem] = useState("") 
  

    useEffect(() => {
       
        dispatch(loadDataset())
    }, [])


    const createDatasetRequest = () => {
        dispatch(addDataset(title, description, dataType, dataurl, problem))
    }


    const navigateTo = (section_in) => {
        //history.push(`/admin3123/`+section_in)
    }



    return(



        <div style={{marginTop:'0px'}}>

            <p className='h3'>Datasets</p>


            <Form.Control type='text' placeholder='Dataset Title' value={title} onChange={(e) => setTitle(e.target.value)} style={{margin:'5px'}}> 
            </Form.Control>

            <Form.Control as='textarea' placeholder='Description' value={description} onChange={(e) => setDescription(e.target.value)} style={{margin:'5px'}}> 
            </Form.Control>

            <Form.Control type='text' placeholder='Data Type' value={dataType} onChange={(e) => setDataType(e.target.value)} style={{margin:'5px'}}> 
            </Form.Control>

            <Form.Control type='text' placeholder='Problem' value={problem} onChange={(e) => setProblem(e.target.value)} style={{margin:'5px'}}> 
            </Form.Control>

            <Form.Control type='text' placeholder='Data Url' value={dataurl} onChange={(e) => setDataURL(e.target.value)} style={{margin:'5px'}}> 
            </Form.Control>


            <Button style={{backgroundColor:'#6b0752', paddingTop:'2px', paddingBottom:'2px'}} onClick={()=>createDatasetRequest()}>Create</Button>


            <hr />

            {datasets && datasets.map((cdata, index)=>(
                <DataCard dataset_in={cdata}/>
            ))}



        </div>

        

        



    )


}

export default AdminDatasetScreen


