import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';


import { useDispatch, useSelector } from 'react-redux'

import { Table, Button, Row, Col, Card, Form, Badge, Container, InputGroup } from 'react-bootstrap'

import { Line } from 'react-chartjs-2';



const LineChart = ({ xdata, ydata, labels }) => {


  const dispatch = useDispatch()


  const data = {
    labels: xdata, // ['1', '2', '3', '4', '5', '6'],
    datasets: [
      {
        label: labels[0], //'y',
        data: ydata, //[12, 19, 3, 5, 2, 3],
        fill: false,
        backgroundColor: 'rgb(204, 0, 0)',
        borderColor: 'rgba(204, 0, 0, 0.2)',
        borderWidth: 1,
        radius:1
      },      
    ],
  };
  
  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };




    useEffect(() => {

    }, []);



    return (
    
        <>

            <Line data={data} options={options} />

        </>
    
    )
}


export default LineChart
