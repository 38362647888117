import React , {useState, useEffect,  Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Row, Col, Card, Button, Form,  OverlayTrigger, Tooltip, InputGroup, ListGroup, Badge } from 'react-bootstrap'

import Moment from 'react-moment';
// import DateFormatted from '../DateFormatted'

import {updateCardTitle, deleteCard} from '../../actions/creatorCardActions'


import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile,
    deviceDetect
  } from "react-device-detect";

export const CardTitle = ({card_id, title_in, cardType, mode_in}) => {

    const dispatch = useDispatch()

    const [newTitle, setNewTitle] = useState("")
    const [sessionMode, setSessionMode] = useState("view")

    const [viewMode, setViewMode] = useState("view")
    const toggleViewMode = () => {
        if(viewMode == 'view'){
            setViewMode('edit')
        }else{
            setViewMode('view')
        }
        
    }

    const onUpdateTitle = () => {

        if(mode_in == 'edit'){
            dispatch(updateCardTitle(card_id, newTitle))
            setViewMode("view")
        }
        
    }

    const onDeleteCard = () => {
        if(mode_in == 'edit'){
            dispatch(deleteCard(card_id))
        }
        
    }



    useEffect(() => {
        
        console.log("Title ", title_in)
        setNewTitle(title_in)

        
    }, [title_in])




    return (

        <>


            <div style={{padding:'5px', marginBottom:'5px', color:'white', borderRadius:'5px'}}>

                    {mode_in == 'edit' && (
                                <>
                                <span>|<i className="fas fa-edit" onClick={()=>toggleViewMode()} style={{color:'black'}}></i></span>
                                <span>|<i className="fas fa-trash" onClick={()=>onDeleteCard()} style={{color:'black'}}></i></span>
                               <span style={{color:'black'}}> | </span>
                                
                                </>
                     )}


                    {viewMode == 'view' ? (
                        <span className='h5' style={{color:'white'}}>
                            <span style={{color:'black'}}>{newTitle} </span>
                           
                        </span>
                    ):
                    (
                        <>
                        {viewMode == 'edit' && (
                                <InputGroup>
                                <Form.Control type='text' placeholder='Title' value={newTitle} onChange={(e) => setNewTitle(e.target.value)} style={{backgroundColor:'white'}}> 
                                </Form.Control>
                                <Badge>
                                <i className="fas fa-check" onClick={()=>onUpdateTitle()} style={{color:'black'}}></i>
                                </Badge>
                                </InputGroup>
                        )}
                        
                        
                        </>
                        
                    )}
            </div>


         
       
        
        </>

       

   
    )
}


export default CardTitle
