import axios from 'axios'
import {setAlert} from './alertActions';


import {
    
    LOAD_QUIZ_ADMIN,
    ADD_QUIZ,
    LOAD_QUIZ_ADMIN_FAIL,
    ADD_QUIZ_QUESTION,
    UPDATE_QUIZ_QUESTION,
    DELETE_QUIZ_QUESTION,
    QUIZ_QUESTIONS_FAIL

} from "../constants/creatorConstants"



export const addQuizQuestion = (quiz_id, instructions, question_type, question, options, answer) => async (dispatch, getState) => {
    try{

        const { userState: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const url = `/api/creatorquiz/questions/${quiz_id}`

        const { data } = await axios.post(url, {instructions, question, options, answer},config)


        console.log("Reference added", data)

        dispatch({
            type: ADD_QUIZ_QUESTION,
            payload: data
        })

       
    } catch(error){


        dispatch({
            type: QUIZ_QUESTIONS_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })
    
        dispatch(setAlert('Sorry unable to add Reference', 'danger'));

    }
}


export const updateQuizQuestion = (quiz_id, question_id, question_type, question, options, answer) => async (dispatch, getState) => {
    try{

        const { userState: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const url = `/api/creatorquiz/questions/${quiz_id}/${question_id}`

        const { data } = await axios.post(url, {question_type, question, options, answer},config)


        console.log("Reference added", data)

        dispatch({
            type: UPDATE_QUIZ_QUESTION,
            payload: data
        })

       
    } catch(error){


        dispatch({
            type: QUIZ_QUESTIONS_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })
    
        dispatch(setAlert('Sorry unable to add Reference', 'danger'));

    }
}


export const deleteQuizQuestion = (quiz_id, question_id) => async (dispatch, getState) => {
    try{

        const { userState: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const url = `/api/creatorquiz/questions/${quiz_id}/${question_id}`

        const { data } = await axios.delete(url,config)


        console.log("deleting question", data)

        dispatch({
            type: DELETE_QUIZ_QUESTION,
            payload: data
        })

       
    } catch(error){


        dispatch({
            type: QUIZ_QUESTIONS_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })
    
        dispatch(setAlert('Sorry unable to add Reference', 'danger'));

    }
}


