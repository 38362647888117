import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';


import { useDispatch, useSelector } from 'react-redux'

import { Table, Button, Row, Col, Card, Form, Badge, Container, InputGroup, ListGroup } from 'react-bootstrap'



const LectureMeta = ({ modelType }) => {


    const dispatch = useDispatch()


    const [lectureTitle, setLectureTitle] = useState('')
    const [lectureDescription, setLectureDescription] = useState('')
    const [lectureURL, setLectureURL] = useState('')
    const [pageTitleTag, setPageTitleTag] = useState('')
    const [pageTitleDescription, setPageDescription] = useState('')    
    const [pageTags, setPageTags] = useState([])


    const addPageMetaRequest = () => {

      
        
    }

  

    useEffect(() => {

    }, []);


    return (
    
        <div style={{margin:'1px'}}>

        


            <Card style={{padding:'20px', margin:'10px'}}>


                <Card.Header style={{borderRadius:'20px' , maxWidth:'30vw'}}>
                    <p className='h5'>Edit Card Meta</p>
                </Card.Header>

                        
                <Form.Control type='text' placeholder='title' value={lectureTitle} onChange={(e) => setLectureTitle(e.target.value)} style={{margin:'5px'}}> 
                </Form.Control>
                    
                       
                <Form.Control as='textarea' rows={3} placeholder='description' value={lectureDescription} onChange={(e) => setLectureDescription(e.target.value)} style={{margin:'5px'}}> 
                </Form.Control>
                
                          
                       
                <Form.Control type='text' placeholder='url' value={lectureURL} onChange={(e) => setLectureURL(e.target.value)} style={{margin:'5px'}}> 
                </Form.Control>
            
            
                <Form.Control type='text' placeholder='tags' value={pageTags} onChange={(e) => setPageTags(e.target.value)} style={{margin:'5px'}}> 
                </Form.Control>
            
            
                <Button onClick={()=>addPageMetaRequest()} style={{width:'20vw'}}>Update</Button>
            
            
            </Card>


    

        </div>
    
    )
}


export default LectureMeta
