import React , {useState, useEffect,  Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Row, Col, Card, Button, Form,  OverlayTrigger, Tooltip, InputGroup, ListGroup, Badge } from 'react-bootstrap'

import Moment from 'react-moment';

import MathJax from 'react-mathjax';

import PublicImageViewer from '../items/PublicImageViewer'

import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';

import {addCardTextItem} from '../../../actions/creatorCardActions'

export const AddTextItem = ({card_id}) => {

    const dispatch = useDispatch()

    const [text, setText] = useState('')
    const [textMode, setTextMode] = useState('text')

    useEffect(() => {
        
        
    }, [])

    const addTextItem = () => {

        dispatch(addCardTextItem(card_id, text, textMode))

    }


    return (

        <>

            <Badge style={{backgroundColor:'#ad9d6c'}}>{textMode}</Badge>

            {textMode == 'image' ? (

                <InputGroup>
                    <Form.Control type='text' placeholder='text' value={text} onChange={(e) => setText(e.target.value)} style={{backgroundColor:'white'}}> 
                    </Form.Control>
                    <Badge>
                        <i className="fas fa-check" onClick={()=>addTextItem()}></i>
                    </Badge>
                </InputGroup>
            ) : (

                <InputGroup>
                    <Form.Control as='textarea' rows={3} placeholder='text' value={text} onChange={(e) => setText(e.target.value)} style={{backgroundColor:'white'}}> 
                    </Form.Control>
                    <Badge>
                        <i className="fas fa-check" onClick={()=>addTextItem()}></i>
                    </Badge>
                </InputGroup>
            )}



            {textMode == 'math' && (
                <>
                    <MathJax.Provider>
                        <div style={{display:'flex', justifyContent:'left'}}>
                            <MathJax.Node formula={text}/>
                        </div>
                    </MathJax.Provider>

                </>
            )}

            {textMode == 'list' && (
                <>
                    <ul>
                        {text.split(',').map((citem, index)=>(
                            <li>{citem}</li>
                        ))}
                    </ul>
                    

                </>
            )}

            {textMode == 'code' && (
                <>
                        <SyntaxHighlighter language="python">
                            {text}

                            {/* {text} */}
                        </SyntaxHighlighter>

                </>
            )}


            {textMode == 'image' && (
                <>
                    <PublicImageViewer imageUrl={text}/>

                </>
            )}


            <InputGroup>
                <Badge style={{backgroundColor:'#dbd9d3', marginRight:'1px'}} onClick={()=>setTextMode('text')}>T</Badge>
                <Badge style={{backgroundColor:'#dbd9d3', marginRight:'1px'}} onClick={()=>setTextMode('math')}>M</Badge>
                <Badge style={{backgroundColor:'#dbd9d3', marginRight:'1px'}} onClick={()=>setTextMode('list')}>L</Badge>
                <Badge style={{backgroundColor:'#dbd9d3', marginRight:'1px'}} onClick={()=>setTextMode('code')}>C</Badge>
                <Badge style={{backgroundColor:'#dbd9d3', marginRight:'1px'}} onClick={()=>setTextMode('image')}>I</Badge>
            </InputGroup>



                 

        </>

    )
}


export default AddTextItem
