export const CREATE_MODEL = 'CREATE_MODEL'
export const MODEL_FAIL   = 'MODEL_FAIL'
export const DELETE_MODEL = 'DELETE_MODEL'
export const EDIT_MODEL   = 'EDIT_MODEL'
export const LOAD_MODELS   = 'LOAD_MODELS'

export const MODEL_DETAIL   = 'MODEL_DETAIL'
export const MODEL_DETAIL_FAIL   = 'MODEL_DETAIL_FAIL'

export const NN_DETAIL   = 'NN_DETAIL'
export const NN_ADD_LAYER   = 'NN_ADD_LAYER'
export const NN_UPDATE_LAYER   = 'NN_UPDATE_LAYER'
export const NN_FAIL   = 'NN_FAIL'

export const LOAD_NODES   = 'LOAD_NODES'
export const LOAD_NODES_FAIL   = 'LOAD_NODES_FAIL'



