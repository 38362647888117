import React, { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import {Alert} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Image, ListGroup, Card, Button, Form, Table, InputGroup, Badge } from 'react-bootstrap'


const SimpleTableHeaderCell = ({ icol, cellType, value, updateValue}) => {

    const [text, setText] = useState(" ") 
    const [viewMode, setViewMode] = useState("view")

    const onSelectCell = () => {
        console.log("Select Cell ", icol)
       
        setViewMode("edit")
    }


    const onDoneCell = () => {
        setViewMode('view')
        updateValue(icol, text)
    }

    useEffect(() => {
 
        if(value){
            setText(value)
        }


    }, [value])

    return (

        <>



        {value ? (

            <>

                {viewMode == 'view' ? (
                    <td onClick={()=>onSelectCell()} style={{minWidth:'10vw', border:'solid', borderWidth:'1px', backgroundColor:'#e9f0f5'}}>
                    
                    <Card  style={{width:'100%', height:'inherit', margin:'0px', padding:'5px', border:'None', border:'None', backgroundColor:'#e9f0f5'}}>
                        <p style={{backgroundColor:'#e9f0f5'}}>{text}</p>
                    </Card>
                    </td>

                ) : 
                (   
                    <td style={{minWidth:'10vw', border:'solid', borderWidth:'1px', backgroundColor:'#e9f0f5'}}>
                        <InputGroup>

                            <Form.Control style={{padding:'5px', margin:'5px', width:'80%'}} type='text' placeholder='Title' value={text} onChange={(e) => setText(e.target.value)}> 
                            </Form.Control>
                        
                        <Badge onClick={()=>onDoneCell()}>
                        <i className="fa fa-check"></i>
                        </Badge>
                        </InputGroup>

                    </td>
                    
                )}



            </>


        ): (

            <>


                {viewMode == 'view' ? (
                    <td onClick={()=>onSelectCell()} style={{minWidth:'10vw', border:'solid', borderWidth:'1px', backgroundColor:'#e9f0f5'}}>
                    {/* <Card  style={{width:'100%', height:'inherit', margin:'0px', padding:'5px', border:'None',backgroundColor:selectedCol == icol ? '#eeebf0' : selectedRow == irow ? '#eeebf0' : 'white'}}>
                        <p>{text}</p>
                    </Card> */}

                    <Card  style={{minWidth:'10vw', border:'solid', borderWidth:'1px', backgroundColor:'#e9f0f5'}}>
                        <p></p>
                    </Card>
                    </td>

                ) : 
                (   
                    <td style={{padding:'0px', minWidth:'100px', fontWeight: '600'}}>
                        <InputGroup>

                            <Form.Control style={{padding:'5px', margin:'5px', width:'80%'}} type='text' placeholder='Title' value={text} onChange={(e) => setText(e.target.value)}> 
                            </Form.Control>
                        
                        <Badge onClick={()=>onDoneCell()}>
                            <i className="fa fa-check"></i>
                        </Badge>
                        </InputGroup>

                    </td>
                    
                )}




            </>

        )}

      
           
        
        
        </>


    )
}


export default SimpleTableHeaderCell
