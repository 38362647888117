import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';


import { useDispatch, useSelector } from 'react-redux'

import { Table, Button, Row, Col, Card, Form, Badge, Container, InputGroup, ListGroup } from 'react-bootstrap'

import {loadLectureQuiz} from '../../actions/lectureActions'

import QuizQuestion from './QuizQuestion'


const QuizTake = ({ lecture_id_in }) => {


    const dispatch = useDispatch()

    const [titleMode, setTitleMode] = useState('view')

    const quiztakeR = useSelector(state => state.quiztakeR)
    const {quiz, questions} = quiztakeR





    useEffect(() => {
        //load Quiz
        dispatch(loadLectureQuiz(lecture_id_in))
    }, [lecture_id_in]);



    return (
    
        <div style={{margin:'1px', padding:'20px'}}>

            <p className='h5' style={{textALign:'center'}}>Take the Quiz to improve your understanding</p>

            <hr />

            {questions && questions.map((ques, index)=>(
                <QuizQuestion question_in={ques}/>
            ))}
                   

        </div>
    
    )
}


export default QuizTake
