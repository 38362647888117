import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';


import { useDispatch, useSelector } from 'react-redux'

import { Table, Button, Row, Col, Card, Form, Badge, Container, InputGroup, ListGroup } from 'react-bootstrap'

import DeepLayersViz from './DeepLayersViz'

const DeepProperties = ({ }) => {


    const dispatch = useDispatch()

    const [titleMode, setTitleMode] = useState('view')



    useEffect(() => {

    }, []);



    return (
    
        <div style={{margin:'1px', marginTop:'10px'}}>


            <p className='h5' style={{textAlign:'center'}}>Model Properties</p>
   

            <Card style={{padding:'20px'}}>
                <p className='h5'>Delete the Model</p>

                <Button style={{width:'20vw'}}>
                    Delete Model
                </Button>
            </Card>



            




        </div>
    
    )
}


export default DeepProperties
